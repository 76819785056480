const axios = require('axios');
const CryptoJS = require('crypto-js');
const { ENDPOINT, AUTHKEY } = require('./constants');

const Auth = (data) => {
  const info = CryptoJS.AES.encrypt(JSON.stringify(data), AUTHKEY).toString();

  return axios.request({
    method: 'POST',
    url: `${ENDPOINT}/auth`,
    headers: { 'Content-Type': 'application/json' },
    data: { info },
  })
    .then((response) => response)
    .catch((error) => false);
};

const Verify = (data) => axios.request({
  method: 'POST',
  url: `${ENDPOINT}/auth/verify`,
  headers: { 'Content-Type': 'application/json' },
  data,
})
  .then((response) => response)
  .catch((error) => error?.response?.data?.message);

module.exports = {
  Auth,
  Verify,
};
