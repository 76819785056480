import React, { useContext, useEffect, useState } from 'react';
import {
  Button, Form, Input, Checkbox,
} from 'antd';
// import axios from 'axios';
import { useTranslation } from 'next-i18next';
import Cookies from 'js-cookie';
import { useSelector, useDispatch } from 'react-redux';
import { CloseCircleFilled } from '@ant-design/icons';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { Auth } from '../../api/auth';
import { SettingActions } from '../reducers/settingReducer';
import ForgotPasswordModal from './ForgotPasswordModal';
import { LayoutContext } from '../contexts/LayoutContextContainer';
import { getBrand } from '../../api/getBrand';
import { getGameCred } from '../../api/getGameCred';
import {
  logoIcon,
  SignupBannerNoLogo,
} from '../../images';

function LoginModal() {
  const branchId = process.env.BRANCH_ID;
  const [form] = Form.useForm();
  const router = useRouter();
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const [remeber, setRemember] = useState(true);
  const loginShow = useSelector((state) => state.setting.loginShow);
  const [show, setShow] = useState(false);
  const isMobile = useSelector((state) => state.setting.isMobile);
  const { toPath } = useContext(LayoutContext);

  const toggleShowModal = () => {
    setShow(!show);
  };

  useEffect(() => {
    dispatch(SettingActions.setLoading(false));
  }, []);

  const accessToSport = async (hasPath) => {
    const gameInfo = await getGameCred({ brandId: process.env.SPORT_ID });

    if (gameInfo?.error) {
      if (!gameInfo?.error.includes('jwt')) {
        dispatch(
          SettingActions.setAlert([
            {
              msg: gameInfo?.error,
            },
          ]),
        );
      }
      return false;
    }

    const brandInfo = await getBrand(process.env.SPORT_ID);
    const gameProfileId = gameInfo?.data?.data?.[0]._id;
    const brandId = brandInfo?.data?._id;
    const gameId = brandInfo?.data?.games?._id;
    router.push(`/game_website?brandId=${brandId}&gameProfileId=${gameProfileId}&gameId=${gameId}${hasPath ? `&toPath=${hasPath}` : ''}`);
  };

  const onFinish = async (values) => {
    dispatch(SettingActions.setLoading(true));
    const result = await Auth({
      ...values, branchId, noVerifications: true,
    });
    if (result) {
      Cookies.set('accessToken', result?.data?.accessToken);
      Cookies.set('userId', result?.data?.userId);
      Cookies.set('username', result?.data?.username);
      Cookies.set('fullName', result?.data?.fullName);
      //   router.push('/');
      dispatch(SettingActions.setLoading(false));
      dispatch(SettingActions.setLoginShow(false));
      // dispatch(SettingActions.setIsLogin(true));

      // if (router.pathname === '/game_website' && router?.query?.gameAccess) {
      //   accessToSport(toPath);
      // }
      if (router.pathname === '/') {
        dispatch(SettingActions.setLoading(true));
        window.location.reload();
      } else if (router.pathname === '/game_website') {
        accessToSport(toPath);
      }
    } else {
      dispatch(SettingActions.setLoading(false));
      // dispatch(SettingActions.setLoginShow(false));
      dispatch(SettingActions.setAlert([{ msg: 'Login error' }]));
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === ' ') {
      e.preventDefault();
    }
  };
  const handleChange = (e) => {
    if (e.currentTarget.value.includes(' ')) {
      e.currentTarget.value = e.currentTarget.value.replace(/\s/g, '');
    }
  };

  return (
    <>
      <div
        id='popup-modal'
        tabIndex='-1'
        className={`md:p-10 overflow-scroll bg-purpleblack fixed top-0 right-0 left-0 z-50 h-modal h-full modal-primary md:px-60 ${loginShow && 'show'}`}
        aria-modal='true'
        role='dialog'
      >
        <div
          className='overlay-closeable'
          onClick={() => {
            if (router.asPath === '/login' || router.asPath === '/register') {
              window.history.replaceState('', '', '/');
            } else {
              window.history.replaceState('', '', router.asPath);
            }
            dispatch(SettingActions.setLoginShow(false));
          }}
        />
        <div className='relative rounded-lg md:rounded-3xl z-10 modal-dialog wide-xl'>
          <div className='absolute -top-6 right-0 left-0 m-auto text-center md:m-0 md:text-end md:-right-4 md:-top-4'>
            <button
              onClick={() => {
                if (router.asPath === '/login' || router.asPath === '/register') {
                  window.history.replaceState('', '', '/');
                } else {
                  window.history.replaceState('', '', router.asPath);
                }
                dispatch(SettingActions.setLoginShow(false));
              }}
              className='rounded-full close-button relative z-10'
            >
              <CloseCircleFilled
                fill='white'
                style={{ fontSize: '35px', color: 'white' }}
              />
            </button>
          </div>
          <div className='md:flex md:min-h-min'>
            <div className='md:w-2/5 flex flex-col justify-between items-center' style={{ lineHeight: 0 }}>
              <div className='leading-none absolute md:top-3 top-6'>
                <Image src={logoIcon} alt='' width={130} height={50} placeholder='empty' />
              </div>
              <div className='md:top-20 '>
                <Image src={SignupBannerNoLogo} alt='' placeholder='empty' />
              </div>
            </div>
            <div className={`md:w-3/5 bounceIn ${!isMobile && 'border-l-2'}`}>
              <div className='px-8 flex items-center w-full h-full'>
                <div className='w-full'>
                  <div className='mt-2 md:mt-0 mb-3 text-3xl font-bold fade-in-left'>
                    Welcome Back
                  </div>
                  <Form
                    id='LoginModal'
                    form={form}
                    onFinish={onFinish}
                    initialValues={{
                      remember: remeber,
                    }}
                  >
                    <div className='fade-in-up'>
                      <div className='ant-col ant-form-item-label'>
                        <label htmlFor='login_username'>Username / Mobile Number</label>
                        <Form.Item
                          name='username'
                          rules={[
                            {
                              required: true,
                              message: 'Please input your username (phone number)!',
                            },
                          ]}
                          className='mb-3'
                        >
                          <Input
                            className='w-full rounded-sm h-10 mt-1 px-4'
                            placeholder='Please enter username or mobile number'
                            onKeyDown={handleKeyDown}
                            onChange={handleChange}
                            id='login_username'
                          />
                        </Form.Item>
                      </div>

                      <div>
                        <label htmlFor='login_password'>{t('login.password')}</label>
                        <Form.Item
                          name='password'
                          rules={[
                            {
                              required: true,
                              message: 'Please input your password!',
                            },
                          ]}
                          className='mb-3'
                        >
                          <Input
                            type='password'
                            className='w-full rounded-sm h-10 mt-1 px-4'
                            placeholder='Please enter password'
                            maxLength={30}
                            id='login_password'
                          />
                        </Form.Item>
                      </div>
                    </div>

                    <div className='mb-5 inline-block flex items-center justify-between fade-in-up'>
                      <div className='flex items-center'>
                        <Checkbox
                          className='mt-1'
                          checked={remeber}
                          onChange={(e) => setRemember(e.target.checked)}
                        />
                        <span className='ml-2 text-xs'>{t('login.savePassword')}</span>
                      </div>
                      <div>
                        <Button
                          onClick={() => {
                            // router.push('/forgot_password');
                            dispatch(SettingActions.setForgotPasswordShow(true));
                            dispatch(SettingActions.setLoginShow(false));
                          }}
                        >
                          <span className='text-xs'>{t('login.forgotPasswordText')}</span>
                        </Button>
                      </div>
                    </div>

                    <Form.Item className='text-center fade-in-right'>
                      <Button
                        htmlType='submit'
                        className='h-12 button-signup rounded-full w-full mb-5'
                      >
                        <span className='text-base font-semibold text-white'>
                          {/* {t('common.login')} */}
                          Go
                        </span>
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <ForgotPasswordModal show={show} toggleModal={toggleShowModal} />
    </>
  );
}

export default LoginModal;
