import React, { useEffect } from 'react';
import { Button } from 'antd';
// import axios from 'axios';
import { useTranslation } from 'next-i18next';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { SettingActions } from '../reducers/settingReducer';
import { menuProfileIcon } from '../../images';

function LoginPage() {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const router = useRouter();

  useEffect(() => {
    dispatch(SettingActions.setLoading(false));
  }, []);

  return (
    <div className='flex items-center w-full gap-2'>
      <div className='w-full text-end'>
        <Button
          className='button-login py-2 lg:py-1 shadow px-4 lg:pl-5 lg:pr-1'
          htmlType='submit'
          onClick={() => {
            window.history.replaceState('', '', '/login');
            dispatch(SettingActions.setLoginShow(true));
          }}
        >
          <div className='flex items-center justify-center'>
            <span className='font-bold text-2xs lg:text-xs lg:mr-1 lg:mr-4 opacity-70'>
              Login
            </span>
            <div
              className='leading-none hidden lg:block'
              style={{
                width: 30,
                height: 30,
              }}
            >
              <Image src={menuProfileIcon} alt='' placeholder='empty' />
            </div>
          </div>
        </Button>
      </div>
      <div className='md:text-end'>
        <Button
          className='button-register py-2 lg:py-2.5 shadow w-16 lg:w-24'
          onClick={() => {
            window.history.replaceState('', '', '/register');
            dispatch(SettingActions.setRegisterShow(true));
            // router.push({
            //   pathname: '/register',
            // }, undefined, { shallow: true });
          }}
        >
          <div className='flex items-center justify-center'>
            <span className='font-bold text-2xs lg:text-xs'>
              Join Now
            </span>
          </div>
        </Button>
      </div>
    </div>
  );
}
export default LoginPage;
