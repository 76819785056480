import {
  Button, Form, Input, message,
} from 'antd';
import React, { useEffect, useState } from 'react';
// import axios from 'axios';
import { useTranslation } from 'next-i18next';
import { useDispatch } from 'react-redux';
import { CloseCircleFilled } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import ReCAPTCHA from 'react-google-recaptcha';
import { SettingActions } from '../reducers/settingReducer';
import { sendEmail } from '../../api/sendEmail';
import NoSSR from '../NoSSR';
import { RECAPTCHA_SITEKEY } from '../../api/constants';

const contactUsEnquiryReasonList = [
  {
    text: 'Deposit / Withdrawal',
  },
  {
    text: 'Bonus issue',
  },
  {
    text: 'Account issue',
  },
  {
    text: 'Products issue',
  },
  {
    text: 'Others',
  },
];

function ContactUsModal({ show, toggleModal }) {
  const branchId = process.env.BRANCH_ID;
  const [form] = Form.useForm();
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const [captchaKey, setCaptchaKey] = useState(null);

  useEffect(() => {
    dispatch(SettingActions.setLoading(false));
  }, []);

  const onFinish = async (values) => {
    if (captchaKey) {
      dispatch(SettingActions.setLoading(true));
      const result = await sendEmail({ ...values, branchId });

      if (result?.data?.status) {
        toggleModal();
        form.resetFields();
        dispatch(SettingActions.setLoading(false));
        dispatch(
          SettingActions.setAlert([
            {
              msg: 'Mail sent successfully!',
            },
          ]),
        );
      } else {
        toggleModal();
        dispatch(SettingActions.setLoading(false));
        dispatch(
          SettingActions.setAlert([
            {
              msg: 'Something went wrong! Please try again later.',
            },
          ]),
        );
      }
    } else {
      dispatch(
        SettingActions.setAlert([
          {
            msg: 'Invalid Captcha!',
          },
        ]),
      );
    }
  };

  return (
    <div
      tabIndex='-1'
      className={
        show
          ? 'md:p-10 overflow-scroll fixed top-0 right-0 left-0 z-50 h-modal h-full modal-primary show md:px-60'
          : 'md:p-10 overflow-scroll fixed top-0 right-0 left-0 z-50 h-modal h-full modal-primary md:px-60'
      }
      role='dialog'
    >
      <div className='overlay-closeable' onClick={() => toggleModal()} />
      <div className='relative py-4 md:pt-5 pb-5 rounded-xl z-10 modal-dialog'>
        <div className='mx-5'>
          <div className='absolute -top-6 right-0 left-0 m-auto text-center md:m-0 md:text-end md:-right-4 md:-top-4'>
            <button
              className='rounded-full close-button relative z-10'
              onClick={() => toggleModal()}
            >
              <CloseCircleFilled
                fill='white'
                style={{ fontSize: '35px', color: 'white' }}
              />
            </button>
          </div>
          <div className='mb-5 text-lg font-medium flex justify-center items-center font-bold'>
            Contact Us
          </div>
          <Form id='ContactUsModal' name='ContactUsModal' form={form} onFinish={onFinish}>
            <div>
              <div>
                <Form.Item
                  name='enquiryReason'
                  className='my-2'
                  label='What can we help you with?'
                  rules={[
                    {
                      required: true,
                      message: 'Please input your enquiry reason!',
                    },
                  ]}
                >
                  <select className='flex justify-between w-full rounded-lg h-10 mt-2 px-2 input-primary'>
                    <option>Please Select</option>
                    {contactUsEnquiryReasonList.map((i, index) => (
                      <option value={i.text} key={`reason-${index}`}>
                        {`${i.text}`}
                      </option>
                    ))}
                  </select>
                </Form.Item>
              </div>

              <div className='my-3'>
                <Form.Item
                  label='Your name'
                  name='name'
                  rules={[
                    {
                      required: true,
                      message: 'Please input your name!',
                    },
                  ]}
                >
                  <Input
                    type='text'
                    className='w-full rounded-lg h-11 mt-1 px-4'
                    placeholder='Please enter your name'
                  />
                </Form.Item>
              </div>

              <div className='my-3'>
                <Form.Item
                  label='E-mail address'
                  name='email1'
                  rules={[
                    {
                      required: true,
                      message: 'Please input your e-mail address!',
                    },
                  ]}
                >
                  <Input
                    type='email'
                    className='w-full rounded-lg h-11 mt-1 px-4'
                    placeholder='Please enter your email'
                  />
                </Form.Item>
              </div>

              <div className='my-3'>
                <div className='ant-col ant-form-item-label'>
                  <p>Captcha</p>
                  <div id='contactus_captcha'>
                    <Form.Item
                      name='captcha'
                      className='mb-3 '
                    >
                      <ReCAPTCHA
                        // size='invisible'
                        sitekey={RECAPTCHA_SITEKEY}
                        onChange={(e) => {
                          setCaptchaKey(e);
                        }}
                      />

                    </Form.Item>
                  </div>

                </div>

              </div>

              <div className='my-3'>
                <Form.Item
                  label='Subject'
                  name='subject'
                  rules={[
                    {
                      required: true,
                      message: 'Please input your enquiry subject!',
                    },
                  ]}
                >
                  <Input
                    type='text'
                    className='w-full rounded-lg h-11 mt-1 px-4'
                    placeholder='Please enter enquiry subject'
                  />
                </Form.Item>
              </div>

              <div className='my-3'>
                <Form.Item
                  label='Details'
                  name='details'
                  rules={[
                    {
                      required: true,
                      message: 'Please input your enquiry details!',
                    },
                  ]}
                >
                  <TextArea
                    className='w-full rounded-lg mt-1 p-4'
                    autoSize={{ minRows: 5, maxRows: 10 }}
                    placeholder='Please enter your enquiry details'
                  />
                </Form.Item>
              </div>
            </div>

            <Form.Item className='text-center'>
              <Button
                htmlType='submit'
                className='rounded-lg w-full my-5 button-primary py-2.5'
              >
                <span className='text-base font-normal leading-none'>Submit</span>
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default ContactUsModal;
