/* eslint-disable jsx-quotes */
import React from 'react';

// markup
function Header(props) {
  return (
    <>
      {props.children}
    </>
  );
}

export default Header;
