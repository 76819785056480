import { CloseCircleFilled } from '@ant-design/icons';
import { Button, Progress, Tooltip } from 'antd';
import Cookies from 'js-cookie';
import moment from 'moment';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Logout } from '../../api/logout';
import { getBonusClaim } from '../../api/promotions/getBonusClaim';
import { User } from '../../api/user';
import {
  avatarIcon, logoutIcon,
  transfIcon,
} from '../../images';
import SideMenu from '../general/SideMenu';
import { SettingActions } from '../reducers/settingReducer';
import { depositCurrency } from '../../api/currency';

export default function MobileMenuModal({
  show,
  setShow,
  toggleModal,
  toggleDepositModal,
  menuList,
  menuList3,
  menuList4,
  menuList5,
  menuList6,
  menuListMobile,
  menuSelected,
  setMenuSelected,
  bonusInfo,
  profileToggleModal,
}) {
  const dispatch = useDispatch();
  const router = useRouter();
  const { t } = useTranslation('common');
  const activeSelected = useSelector((state) => state.setting.selected);
  const [logoutNotificationShow, setLogoutNotificationShow] = useState(false);
  const [data, setData] = useState({});
  const { balance } = useSelector((state) => state.setting);
  const isId = Cookies.get('userId');
  const accessToken = Cookies.get('accessToken');
  const [activeBonusClaim, setActiveBonusClaim] = useState({});
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  useLayoutEffect(() => {
    if (isId && accessToken) {
      getUserData();
    }
  }, [isId, accessToken]);

  const getUserData = async () => {
    const result = await User({ _id: isId });
    if (result) {
      setData(result);
    }
  };

  function percentage(partialValue, totalValue) {
    return (100 * partialValue) / totalValue;
  }

  const logout = () => {
    Logout();
    setLogoutNotificationShow(true);
    setTimeout(() => {
      setLogoutNotificationShow(false);
      router.push('/');
      toggleModal(false);
    }, 3000);
  };

  const getActiveBonusClaim = async () => {
    const result = await getBonusClaim({
      branchId: process.env.BRANCH_ID,
      userId: isId,
      'endAt[$gt]': moment().toDate(),
      '$sort[createdAt]': -1,
      $limit: 1,
    });
    if (result?.data?.length > 0) {
      setActiveBonusClaim(result?.data[0]);
    }
  };

  useEffect(() => {
    getActiveBonusClaim();
  }, [isId]);

  return (
    <div>
      <div
        id='mobile-menu-popup-modal'
        tabIndex='-1'
        className={
          show
            ? 'overflow-scroll fixed top-0 right-0 left-0 z-50 h-modal h-full modal-primary show'
            : 'overflow-scroll fixed top-0 right-0 left-0 z-50 h-modal h-full modal-primary'
        }
        role='dialog'
      >
        <div className='overlay-closeable' onClick={() => toggleModal()} />
        <div className='relative px-5 pt-4 md:pt-8 pb-10 rounded-lg z-10 modal-dialog' style={{ backgroundColor: '#EDEDED' }}>
          <div className='absolute -top-6 right-0 left-0 m-auto text-center md:m-0 md:text-end md:-right-4 md:-top-4'>
            <button
              type='button'
              data-modal-toggle='mobile-menu-popup-modal'
              className='rounded-full close-button relative z-10'
              onClick={() => {
                toggleModal();
              }}
            >
              <CloseCircleFilled
                fill='white'
                style={{ fontSize: '35px', color: 'white' }}
              />
            </button>
          </div>
          <div className='body mb-6'>
            {
              Cookies.get('accessToken')
              && (
                <>
                  <div className=''>
                    <div className='flex justify-start items-center gap-4 mb-3'>
                      <div
                        className='rounded-full'
                        style={{
                          filter: 'invert(100%) sepia(0%) saturate(61%) hue-rotate(340deg) brightness(114%) contrast(86%)',
                        }}
                      >
                        <Image src={avatarIcon} width={50} height={50} alt='Cricket betting sites in India' placeholder='empty' />
                      </div>

                      <div className='text-base  font-semibold pb-2 capitalize'>
                        {data.fullName ? data.fullName : ''}
                      </div>
                    </div>

                    <div
                      className='py-2 px-4 rounded-lg mb-3 text-xs'
                      style={{ background: '#ffffff' }}
                    >
                      {depositCurrency}
                      {balance
                        ? balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        : '0.00'}
                    </div>

                    <div className='my-3'>
                      <div className='mb-1 mt-4'>
                        <div className='flex items-center justify-start gap-1'>
                          <p className='text-xs font-normal'>Turnover mission progress</p>
                          {
                            activeBonusClaim?.titles?.en
                            && (
                              <div
                                className='relative inline-block cursor-pointer mt-1'
                                onMouseEnter={() => setIsTooltipVisible(true)}
                                onMouseLeave={() => setIsTooltipVisible(false)}
                              >
                                <span>
                                  <Image src={transfIcon} width={18} height={18} alt='Cricket betting sites in India' />
                                </span>

                                {isTooltipVisible && (
                                  <div className='absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-max px-2 py-1 text-xs text-white bg-black rounded shadow-lg z-10'>
                                    {activeBonusClaim?.titles?.en}
                                  </div>
                                )}
                              </div>

                            )
                          }
                        </div>
                      </div>
                      <Progress
                        percent={percentage(bonusInfo?.a, bonusInfo?.b)}
                        strokeColor={{
                          '0%': '#23ab5e',
                          '100%': '#23ab5e',
                        }}
                        showInfo={false}
                        size='small'
                      />
                      <p
                        className='align-start justify-start font-normal text-xxs'
                      >
                        {
                          `(${depositCurrency}${bonusInfo?.a} / ${depositCurrency}${bonusInfo?.b})`
                        }
                      </p>
                    </div>
                  </div>
                  <div className='grid grid-cols-2 gap-2 mb-4'>
                    <Link href='/deposit'>
                      <div
                        className='py-2 px-4 text-center rounded-full button-primary cursor-pointer'
                        onClick={() => {
                          toggleModal();
                        }}
                      >
                        <span className='text-xs md:text-sm font-normal text-white'>
                          Deposit
                        </span>
                      </div>
                    </Link>
                    <Link href='/withdraw'>
                      <div
                        className='py-2 px-4 text-center rounded-full button-secondary cursor-pointer'
                        style={{ backgroundColor: '#EDEDED' }}
                        onClick={(e) => {
                          if (!Cookies.get('accessToken')) {
                            dispatch(SettingActions.setLoginShow(true));
                          } else {
                            toggleModal();
                            profileToggleModal();
                          }
                        }}
                      >
                        <span className='text-xs md:text-sm font-bold'>
                          Withdraw
                        </span>
                      </div>
                    </Link>
                  </div>
                </>
              )
            }

            <SideMenu
              menuList={menuList}
              setMenuSelected={setMenuSelected}
              className='mb-4 rounded-lg overflow-hidden'
            />

            <SideMenu
              menuList={menuList4}
              setMenuSelected={setMenuSelected}
              className='mb-4 rounded-lg overflow-hidden'
            />

            <SideMenu
              menuList={menuList5}
              setMenuSelected={setMenuSelected}
              className='mb-4 rounded-lg overflow-hidden'
            />

            <SideMenu
              menuList={menuList6}
              setMenuSelected={setMenuSelected}
              className='mb-4 rounded-lg overflow-hidden'
            />

            <SideMenu
              menuList={menuList3}
              setMenuSelected={setMenuSelected}
              className='mb-4 rounded-lg overflow-hidden'
            />

          </div>
          {
            Cookies.get('accessToken')
            && (
              <div className='footer'>
                <Button
                  onClick={logout}
                  className='rounded-lg text-center py-2.5 w-full'
                  style={{ backgroundColor: '#FF0000' }}
                >
                  <span className=' text-sm font-semibold uppercase text-white'>
                    {t('profile.logout')}
                  </span>
                </Button>
              </div>
            )
          }
        </div>
      </div>

      <div className={`${logoutNotificationShow ? '' : 'hidden'}`}>
        <div
          tabIndex='-1'
          className='md:p-10 overflow-scroll fixed top-0 right-0 left-0 z-50 h-modal h-full modal-primary show md:px-60'
          role='dialog'
        >
          <div
            className='overlay-closeable'
            onClick={() => {
              setLogoutNotificationShow(false);
              router.push('/');
              toggleModal(false);
            }}
          />
          <div className='relative py-4 md:pt-5 pb-5 rounded-xl z-10 modal-dialog'>
            <div className='mx-5'>
              <div className='flex justify-end'>
                <button
                  onClick={() => {
                    setLogoutNotificationShow(false);
                    router.push('/');
                    toggleModal(false);
                  }}
                >
                  <CloseCircleFilled
                    fill='white'
                    style={{ fontSize: '20px' }}
                  />
                </button>
              </div>
              <div className='mb-5 text-lg gap-6 font-medium  flex flex-col justify-center items-center'>
                <div
                  className='rounded-full '
                  style={{
                    filter: 'invert(100%) sepia(0%) saturate(61%) hue-rotate(340deg) brightness(114%) contrast(86%)',
                  }}
                >
                  <Image src={logoutIcon} width={60} height={60} alt='Cricket betting sites in India' placeholder='empty' />
                </div>
                <p>You have been logged out</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
