import React, { useRef, useEffect, useState } from 'react';
import {
  Button, Form, Input,
  message,
} from 'antd';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import {
  CloseCircleFilled,
} from '@ant-design/icons';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import Cookies from 'js-cookie';
import { useSelector, useDispatch } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import Image from 'next/image';
import _ from 'lodash';
import { Register } from '../../api/registration';
import { SendVerifyCode } from '../../api/sendVerifyCode';
import { SettingActions } from '../reducers/settingReducer';
import StrengthMeter from './StrengthMeter';
import { SendEmailVerifyCode } from '../../api/sendEmailVerifyCode';
import {
  logoIcon,
  SignupBannerNoLogo,
} from '../../images';

function RegisterModal() {
  const branchId = process.env.BRANCH_ID;
  const { t } = useTranslation('common');
  const router = useRouter();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [referrerCode, setReferrerCode] = useState('');
  const registerShow = useSelector((state) => state.setting.registerShow);
  const [isError, setError] = useState(null);
  const [passwordStatus, setPasswordStatus] = useState(null);
  const [emailOTP, setEmailOTP] = useState('');
  // const [emailVerified, setEmailVerified] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [invalidCount, setInvalidCount] = useState(0);
  const [captchaKey, setCaptchaKey] = useState(null);
  const Ref = useRef(null);
  const [time, setTime] = useState('');
  const referrer_link = router.query.referrer;
  const referrer_local = (typeof window !== 'undefined' && window.localStorage) ? (localStorage.getItem('referrer') ? (JSON.parse(localStorage.getItem('referrer'))) : '') : '';
  const referrer_cookies = Cookies.get('referrer') ? (JSON.parse(Cookies.get('referrer'))) : '';
  const [originAffiliate, setOriginAffiliate] = useState('');
  const isMobile = useSelector((state) => state.setting.isMobile);

  // Regular expression for restricting input to alphanumeric characters only
  const usernameRegExp = /^[a-zA-Z0-9]+$/;

  // Custom validation function for the UserName
  const validateUsername = (_, value) => {
    if (!value) {
      return Promise.reject(new Error(''));
    }
    if (usernameRegExp.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Username must not contain special characters and space'));
  };

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total, minutes, seconds,
    };
  };
  const startTimer = (e) => {
    const { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTime(
        `${minutes > 9 ? minutes : `0${minutes}`}:${seconds > 9 ? seconds : `0${seconds}`}`,
      );
    }
  };
  const clearTimer = (e) => {
    setTime('05:00');
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };
  const getDeadtime = () => {
    const deadline = new Date();
    deadline.setMinutes(deadline.getMinutes() + 5);
    return deadline;
  };

  useEffect(() => {
    if (time === '00:00') {
      setShowModal(false);
    }
  }, [time]);

  // Function to validate "confirm password" field
  const validateConfirmPassword = (_, value) => {
    const { password } = form.getFieldsValue();
    if (value && value !== password) {
      return Promise.reject('The two passwords that you entered do not match!');
    }

    return Promise.resolve();
  };

  // Function to handle changes in the "password" field
  const handlePasswordChange = () => {
    form.validateFields(['confirmPassword']);

    setError(null);
    let caps; let small; let num; let
      specialSymbol;

    const password = form.getFieldValue('password');
    if (password.length < 4) {
      setError(
        'Password should contain minimum 4 characters, with one UPPERCASE, lowercase, number and special character: @$! % * ? &',
      );
    } else {
      caps = (password.match(/[A-Z]/g) || []).length;
      small = (password.match(/[a-z]/g) || []).length;
      num = (password.match(/[0-9]/g) || []).length;
      specialSymbol = (password.match(/\W/g) || []).length;
      if (caps < 1) {
        setError('Must add one UPPERCASE letter');
      } else if (small < 1) {
        setError('Must add one lowercase letter');
      } else if (num < 1) {
        setError('Must add one number');
      } else if (specialSymbol < 1) {
        setError('Must add one special symbol: @$! % * ? &');
      }
    }
  };

  useEffect(() => {
    if (referrer_link) {
      form.setFieldsValue({ referrer: referrer_link });
      setOriginAffiliate(referrer_link);
    }
    if (referrer_cookies) {
      if (!isEmpty(referrer_cookies)) {
        if (moment(referrer_cookies.expiryDate) >= moment()) {
          if (referrer_cookies.referrerCode) {
            form.setFieldsValue({ referrer: referrer_cookies.referrerCode });
            setOriginAffiliate(referrer_cookies.referrerCode);
          }
        }
      }
    }
    if (referrer_local) {
      if (!isEmpty(referrer_local)) {
        if (moment(referrer_local.expiryDate) >= moment()) {
          if (referrer_local.referrerCode) {
            form.setFieldsValue({ referrer: referrer_local.referrerCode });
            setOriginAffiliate(referrer_local.referrerCode);
          }
        }
      }
    }
  }, [referrer_link, referrer_cookies, referrer_local]);

  const onFinish = async (values) => {
    if (passwordStatus === 'weak' || isError) {
      const errorMessage = passwordStatus === 'weak' ? 'Please choose a stronger password.' : isError;
      return message.error(errorMessage);
    }
    if (!captchaKey) {
      const errorMessage = 'Please check reCAPTCHA';
      return message.error(errorMessage);
    }

    // get origin affiliate
    if (originAffiliate) {
      values.originAffiliate = originAffiliate;
    }

    dispatch(SettingActions.setLoading(true));

    const result = await Register({
      captchaKey,
      ...values,
      branchId,
      noVerifications: true,
    });
    if (!result?.error) {
      // store token
      Cookies.set('accessToken', result?.data?.accessToken);
      Cookies.set('userId', result?.data?.userId);
      Cookies.set('username', result?.data?.username);
      Cookies.set('fullName', result?.data?.fullName);
      dispatch(SettingActions.setAlert([{ msg: 'Registration Success' }]));
      dispatch(SettingActions.setLoginShow(false));
      dispatch(SettingActions.setRegisterShow(false));
      dispatch(SettingActions.setLoading(false));

      Cookies.remove('referrer');
      localStorage.removeItem('referrer');

      // await SubmitEmailVerifyCode({
      //   email: values.email,
      //   code: emailOTP,
      //   branchId,
      // });
      // setEmailOTP('');
      // setEmailVerified(false);
      form.resetFields();
      router.push('/');
    } else {
      // show error
      dispatch(SettingActions.setLoginShow(false));
      // dispatch(SettingActions.setRegisterShow(false));
      dispatch(SettingActions.setLoading(false));
      dispatch(
        SettingActions.setAlert([
          { msg: `Registration Fail : ${result?.error?.msg}` },
        ]),
      );
    }
  };

  const sendCode = async (affiliate) => {
    const phoneNumber = form.getFieldValue('phoneNumber');

    const hasError = form.getFieldError('phoneNumber').length > 0;

    if (hasError) {
      dispatch(SettingActions.setAlert([{ msg: 'Invalid phone number format!' }]));
      return false;
    }
    dispatch(SettingActions.setLoading(true));
    setTimeout(async () => {
      const result = await SendVerifyCode({
        phoneNumber,
        branchId,
        type: '1', // registration
        originAffiliate: affiliate,
      });
      if (!result?.error) {
        dispatch(SettingActions.setAlert([{ msg: 'Code Sent' }]));
      } else {
        dispatch(
          SettingActions.setAlert([{ msg: `Fail : ${result?.error?.msg}` }]),
        );
      }
      dispatch(SettingActions.setLoading(false));
    }, 1000);
  };

  // const handleSuccess = () => setCaptchaPassed(true);
  // const handleFailure = () => setCaptchaPassed(false);

  const handleKeyDown = (e) => {
    if (e.key === ' ') {
      e.preventDefault();
    }
  };
  const handleChange = (e) => {
    if (e.currentTarget.value.includes(' ')) {
      e.currentTarget.value = e.currentTarget.value.replace(/\s/g, '');
    }
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const sendEmailCode = async () => {
    const email = form.getFieldValue('email');
    if (email && /\S+@\S+\.\S+/.test(email)) {
      dispatch(SettingActions.setLoading(true));
      const result = await SendEmailVerifyCode({
        email,
        branchId,
      });
      if (!result?.error) {
        // dispatch(SettingActions.setAlert([{ msg: 'Code Sent' }]));
        toggleModal();
        clearTimer(getDeadtime());
      } else {
        dispatch(
          SettingActions.setAlert([{ msg: `Fail : ${result?.error?.msg}` }]),
        );
      }
      dispatch(SettingActions.setLoading(false));
    } else {
      dispatch(
        SettingActions.setAlert([{ msg: 'Email is not valid format!' }]),
      );
    }
  };

  // const submitVerifyCode = async () => {
  //   const email = form.getFieldValue('email');
  //   if (emailOTP.length >= 6) {
  //     dispatch(SettingActions.setLoading(true));

  //     const result = await RegisterCheckEmailVerified({
  //       email,
  //       branchId,
  //       code: emailOTP,
  //     });
  //     // not yet in used
  //     if (result?.data) {
  //       toggleModal();
  //       dispatch(SettingActions.setAlert([{ msg: 'Email is available!' }]));
  //       setEmailVerified(true);
  //       dispatch(SettingActions.setLoading(false));
  //     } else {
  //       dispatch(
  //         SettingActions.setAlert([{ msg: 'Invalid OPT or Email is in used!' }]),
  //       );
  //       dispatch(SettingActions.setLoading(false));
  //       setInvalidCount(invalidCount + 1);
  //       message.warning(`Invalid count ${invalidCount + 1}`);
  //       if (invalidCount + 1 >= 3) {
  //         dispatch(
  //           SettingActions.setAlert([{ msg: 'Please request new OTP!' }]),
  //         );
  //         setInvalidCount(0);
  //         setShowModal(false);
  //       }
  //     }
  //   }
  // };

  // const handleEmailOTP = (value, index) => {
  //   setEmailOTP(value);
  // };

  return (
    <div
      id='popup-modal'
      tabIndex='-1'
      className={
        registerShow
          ? 'md:p-10 overflow-scroll bg-purpleblack fixed top-0 right-0 left-0 z-50 h-modal h-full modal-primary show md:px-60'
          : 'md:p-10 overflow-scroll bg-purpleblack fixed top-0 right-0 left-0 z-50 h-modal h-full modal-primary md:px-60'
      }
      aria-modal='true'
      role='dialog'
    >
      <div
        className='overlay-closeable'
        onClick={() => {
          if (router.asPath === '/login' || router.asPath === '/register') {
            window.history.replaceState('', '', '/');
          } else {
            window.history.replaceState('', '', router.asPath);
          }
          dispatch(SettingActions.setRegisterShow(false));
        }}
      />
      <div className='relative rounded-3xl z-10 modal-dialog wide-xl'>
        <div className='absolute -top-6 right-0 left-0 m-auto text-center md:m-0 md:text-end md:-right-4 md:-top-4'>
          <button
            onClick={() => {
              if (router.asPath === '/login' || router.asPath === '/register') {
                window.history.replaceState('', '', '/');
              } else {
                window.history.replaceState('', '', router.asPath);
              }
              dispatch(SettingActions.setRegisterShow(false));
            }}
            className='rounded-full close-button relative z-10'
          >
            <CloseCircleFilled
              fill='white'
              style={{ fontSize: '35px', color: 'white' }}
            />
          </button>
        </div>
        <div className='md:flex pt-2'>
          <div className='md:w-2/5 flex flex-col justify-between items-center' style={{ lineHeight: 0 }}>
            <div className='leading-none absolute md:top-3 top-6'>
              <Image src={logoIcon} alt='' width={130} height={50} placeholder='empty' />
            </div>
            <div className='md:top-20 '>
              <Image src={SignupBannerNoLogo} alt='' placeholder='empty' />
            </div>
          </div>
          <div className={`md:w-3/5 bounceIn ${!isMobile && 'border-l-2'}`}>
            <div className='px-8 flex items-center w-full h-full'>
              <div className='w-full'>
                <div className='mt-4 mb-2 text-2xl font-bold fade-in-left'>
                  Sign Up Now
                </div>
                <Form
                  form={form}
                  initialValues={{
                    referrer: referrerCode,
                    dialCode: '+91',
                  }}
                  onFinish={onFinish}
                >
                  <div className='fade-in-up'>
                    <div className='grid grid-cols-1 gap-2 mb-5'>
                      <Form.Item
                        name='username'
                        label={<label>{`${t('login.username')}`}</label>}
                        rules={[
                          {
                            required: true,
                            message: 'Please input your username !',
                          },
                          { validator: validateUsername },
                        ]}
                      >
                        <Input
                          className='w-full rounded-sm h-10 mt-1 px-4'
                          placeholder='Please enter username'
                          onKeyDown={handleKeyDown}
                          onChange={handleChange}
                        />
                      </Form.Item>
                      <Form.Item
                        name='fullName'
                        label={(
                          <>
                            <label>{`${t('register.fullName')}`}</label>
                            {/* <div role='alert' className='ant-form-item-explain-error'>Full name must be the same with your bank account name for deposit & withdrawal verification.</div> */}
                          </>
                        )}
                        rules={[
                          {
                            required: true,
                            message: 'Please input full name !',
                          },
                        ]}
                      >
                        <Input
                          className='w-full rounded-sm h-10 mt-1 px-4'
                          placeholder='Please enter your full name (Must be the same with your Bank Account )'
                        />
                      </Form.Item>
                    </div>

                    <Form.Item
                      name='email'
                      label={t('register.email')}
                      rules={[
                        {
                          required: true,
                          message: 'Please input your email !',
                        },
                      ]}
                      className='mb-5'
                    >
                      <Input
                        className='w-full rounded-sm h-10 mt-1 px-4'
                        placeholder='Please enter email'
                      />
                    </Form.Item>

                    <Form.Item
                      name='phoneNumber'
                      label={t('register.mobileNo')}
                      rules={[
                        {
                          required: true,
                          message: 'Please input your phone no!',
                        },
                        {
                          pattern: /^91\d{10}$/, // Regex pattern for 91 followed by exactly 10 digits
                          message: 'Invalid phone number format! It must start with 91 and have exactly 12 digits.',
                        },
                      ]}
                      className='mb-5'
                    >
                      <Input
                        className='w-full rounded-sm h-10 mt-1 px-4'
                        placeholder='Please enter phone number'
                      />
                    </Form.Item>

                    <div className='grid grid-cols-10 gap-2 mb-5'>
                      <div className='col-span-7 mb:col-span-3'>
                        <Form.Item
                          name='verifyContactNoCode'
                          label={t('register.verifyContactNoCode')}
                          rules={[
                            {
                              required: true,
                              message: 'Please input code!',
                            },
                            {
                              pattern: /^\d*$/, // Regex pattern for any number of digits
                              message: 'Only number allow.',
                            },
                          ]}
                        >
                          <Input
                            className='w-full rounded-sm h-10 mt-1 px-4'
                            placeholder='Please enter code'
                          />
                        </Form.Item>
                      </div>
                      <div className='col-span-3 mb:col-span-2 mt-5'>
                        <Button onClick={() => sendCode(referrer_cookies)} className='h-10 rounded button-signup w-full'>
                          <span className='text-xs text-white font-normal'>
                            Send Code
                          </span>
                        </Button>
                      </div>
                    </div>

                    <div className='grid grid-cols-2 gap-2'>
                      <div className='ant-col ant-form-item-label'>
                        <label htmlFor='register_confirm_password'>{`${t('register.password')}`}</label>
                        <Form.Item
                          name='password'
                          rules={[
                            {
                              required: true,
                              message: 'Please input your password !',
                            },
                          ]}
                          className='mb-1'
                        >
                          <Input
                            type='password'
                            className='w-full rounded-sm h-10 mt-1 px-4'
                            onChange={handlePasswordChange}
                            placeholder='Please enter password'
                            maxLength={30}
                            id='register_password'
                          />
                        </Form.Item>
                        <div className='mb-2'>
                          {isError !== null && (
                            <p className='errors'>
                              {' '}
                              -
                              {' '}
                              {isError}
                            </p>
                          )}
                          <StrengthMeter password={form.getFieldValue('password')} actions={setPasswordStatus} />
                        </div>
                      </div>
                      <div className='ant-col ant-form-item-label'>
                        <label htmlFor='register_confirm_password'>{t('register.confirmPassword')}</label>
                        <Form.Item
                          name='confirmPassword'
                          dependencies={['password']}
                          rules={[
                            {
                              required: true,
                              message: 'Please input your password!',
                            },
                            {
                              validator: validateConfirmPassword,
                            },
                          ]}
                          className='mb-3'
                        >
                          <Input
                            type='password'
                            className='w-full rounded-sm h-10 mt-1 px-4'
                            placeholder='Please enter confirm password'
                            maxLength={30}
                            id='register_confirm_password'
                          />
                        </Form.Item>
                      </div>

                    </div>

                    <div className='text-end mb-5'>
                      <ReCAPTCHA
                        // size='invisible'
                        sitekey='6Le2ggQpAAAAAJY9ZSh6UYeP8GDwsUTY-mPhtOpy'
                        onChange={(e) => {
                          setCaptchaKey(e);
                        }}
                      />
                    </div>

                    <Form.Item
                      name='referrer'
                      label={t('register.referrerCode')}
                      style={{ margin: 0 }}
                    >
                      <Input
                        className='w-full rounded-sm h-10 mt-1 px-4'
                        placeholder='Eg: HveDqsb6'
                      />
                    </Form.Item>
                  </div>

                  <Form.Item className='text-center fade-in-right'>
                    <Button
                      htmlType='submit'
                      className='h-12 button-signup rounded-full w-full mt-5 mb-5'
                    >
                      <span className='text-base font-semibold text-white'>
                        {/* {t('common.login')} */}
                        Go
                      </span>
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <RegisterVerifyEmailModal
        time={time}
        userInfo={{ userInfo: { email: form.getFieldValue('email') } }}
        show={showModal}
        toggleModal={toggleModal}
        submitVerifyCode={submitVerifyCode}
        handleEmailOTP={handleEmailOTP}
      /> */}
    </div>
  );
}

export default RegisterModal;
