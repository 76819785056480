const axios = require('axios');
const CryptoJS = require('crypto-js');
const { ENDPOINT, AUTHKEY } = require('./constants');

const Register = (data) => axios.request({
  method: 'POST',
  url: `${ENDPOINT}/registration`,
  headers: { 'Content-Type': 'application/json' },
  data,
})
  .then((res) => {
    const info = CryptoJS.AES.encrypt(JSON.stringify(data), AUTHKEY).toString();

    return axios.request({
      method: 'POST',
      url: `${ENDPOINT}/auth`,
      headers: { 'Content-Type': 'application/json' },
      data: { info },
    });
  })
  .then((response) => response)
  .catch((error) => ({ error: error?.response?.data }));

module.exports = {
  Register,
};
