import React from 'react';
import { useRouter } from 'next/router';
import { useSelector, useDispatch } from 'react-redux';
import Image from 'next/image';
import Cookies from 'js-cookie';
import Link from 'next/link';
import {
  ChevronDown,
  logoIcon,

  NavmenuLiveEvents,
  NavmenuLivecasino,
  NavmenuUpcomingMatches,
  NavmenuCardGames,
  NavmenuInstantGames,
  NavmenuSlot,
  NavmenuTableGames,
} from '../../images';
import { SettingActions } from '../reducers/settingReducer';

function MenuSection({
  setToPath,
  accessToSport,
}) {
  const router = useRouter();
  const dispatch = useDispatch();
  const activeSelected = useSelector((state) => state.setting.selected);

  const menuRow = [
    // {
    //   key: -1,
    //   label: 'Home',
    //   type: '',
    //   action: async () => {
    //     router.push('/');
    //   },
    // },
    // {
    //   key: 0,
    //   label: 'Live Events',
    //   svg: NavmenuLiveEvents,
    //   type: '',
    //   action: async () => {
    //     dispatch(SettingActions.setSelected(0));
    //     if (Cookies.get('accessToken')) {
    //       accessToSport('/live/events');
    //     } else {
    //       setToPath('/live/events');
    //       accessToSport('/live/events', true);
    //     }
    //   },
    // },
    // {
    //   key: 1,
    //   label: 'Upcoming Matches',
    //   svg: NavmenuUpcomingMatches,
    //   type: '',
    //   action: () => {
    //     dispatch(SettingActions.setSelected(1));
    //     if (Cookies.get('accessToken')) {
    //       accessToSport('/calendar/upcoming');
    //     } else {
    //       setToPath('/calendar/upcoming');
    //       accessToSport('/calendar/upcoming', true);
    //     }
    //   },
    // },
    {
      key: 6,
      label: 'Instant Games',
      svg: NavmenuInstantGames,
      class: 'hidden xl:flex',
      type: '',
      action: () => {
        dispatch(SettingActions.setLoading(true));
        dispatch(SettingActions.setSelected(6));
        router.push({
          pathname: '/instant-games',
        });
      },
    },
    {
      key: 2,
      label: 'Live Casino',
      svg: NavmenuLivecasino,
      type: '',
      action: () => {
        dispatch(SettingActions.setLoading(true));
        dispatch(SettingActions.setSelected(2));
        router.push({
          pathname: '/live-casino',
        });
      },
    },
    {
      key: 3,
      label: 'Slots',
      svg: NavmenuSlot,
      class: 'hidden lg:flex',
      type: '5',
      action: () => {
        dispatch(SettingActions.setLoading(true));
        dispatch(SettingActions.setSelected(3));
        router.push({
          pathname: '/slots',
        });
      },
    },
    {
      key: 4,
      label: 'Table Games',
      svg: NavmenuTableGames,
      class: 'hidden xl:flex',
      type: '7',
      action: () => {
        dispatch(SettingActions.setLoading(true));
        dispatch(SettingActions.setSelected(4));
        router.push({
          pathname: '/table-games',
        });
      },
    },
    {
      key: 5,
      label: 'Card Games',
      svg: NavmenuCardGames,
      class: 'hidden xl:flex',
      type: '',
      action: () => {
        dispatch(SettingActions.setLoading(true));
        dispatch(SettingActions.setSelected(5));
        router.push({
          pathname: '/card-games',
        });
      },
    },

  ];

  const dropdownContent = [
    {
      key: 3,
      label: 'Slots',
      svg: NavmenuSlot,
      class: 'lg:hidden',
      type: '5',
      action: () => {
        dispatch(SettingActions.setLoading(true));
        dispatch(SettingActions.setSelected(3));
        router.push({
          pathname: '/slots',
        });
      },
    },
    {
      key: 4,
      label: 'Table Games',
      svg: NavmenuTableGames,
      type: '7',
      action: () => {
        dispatch(SettingActions.setLoading(true));
        dispatch(SettingActions.setSelected(4));
        router.push({
          pathname: '/tables',
        });
      },
    },
    {
      key: 5,
      label: 'Card Games',
      svg: NavmenuCardGames,
      type: '',
      action: () => {
        dispatch(SettingActions.setLoading(true));
        dispatch(SettingActions.setSelected(5));
        router.push({
          pathname: '/cards',
        });
      },
    },
    {
      key: 6,
      label: 'Instant Games',
      svg: NavmenuInstantGames,
      type: '',
      action: () => {
        dispatch(SettingActions.setLoading(true));
        dispatch(SettingActions.setSelected(6));
        router.push({
          pathname: '/instant-games',
        });
      },
    },
  ];

  return (
    <div className='menu-responsive hidden md:flex items-center justify-center flex-wrap ml-4 gap-4'>
      {
        router.pathname === '/game_website'
        && (
          <div className='main-menu-logo cursor-pointer flex items-end pb-0.5'>
            <Link href='/'>
              <Image

                alt='Live Casino India'
                placeholder='empty'
                onClick={() => {
                  dispatch(SettingActions.setLoading(true));
                  router.push('/');
                  setTimeout(() => {
                    dispatch(SettingActions.setLoading(false));
                  }, 500);
                }}
                src={logoIcon}
              />
            </Link>
          </div>
        )
      }
      {menuRow.map((i, index) => {
        const isActive = activeSelected === i.key;
        return (
          <div
            className={`cursor-pointer menu-item py-4 flex items-center ${isActive && 'active'} ${i.class}`}
            key={i.key}
            // onClick={(e) => (i.type === '' ? i.action() : e.preventDefault())}
            onClick={(e) => i.action()}
          >
            <span className='mr-1 leading-none' style={{ width: 16 }}><Image src={i.svg} alt={i.label} placeholder='empty' /></span>
            <span className='font-normal text-3xs lg:text-xs capitalize'>{i.label}</span>
          </div>
        );
      })}
      <div
        className='cursor-pointer py-4 dropdown'
      >
        <div className='flex items-center xl:hidden'>
          <span className='font-normal text-3xs lg:text-xs capitalize'>More</span>
          <span className='ml-1 inline-block' style={{ width: 10 }}><Image src={ChevronDown} alt='' placeholder='empty' /></span>
        </div>
        <div className='dropdown-content rounded-lg shadow'>
          {dropdownContent.map((i, index) => (
            <div
              className={`cursor-pointer menu-item py-3 flex items-center ${i.class}`}
              key={i.key}
              // onClick={(e) => (i.type === '' ? i.action() : e.preventDefault())}
              onClick={(e) => i.action()}
            >
              <span className='mr-1 leading-none' style={{ width: 16 }}><Image src={i.svg} alt='' placeholder='empty' /></span>
              <span className='font-normal text-2xs lg:text-xs capitalize'>{i.label}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MenuSection;
