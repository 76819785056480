import React, { useState } from 'react';
import Image from 'next/image';
import { useSelector } from 'react-redux';

function SideMenu({
  menuList,
  className,
}) {
  const activeSelected = useSelector((state) => state.setting.selected);
  const [menuSelected, setMenuSelected] = useState(null);

  return (
    <div className={className}>
      {menuList.map((i, index) => (
        <div
          key={index}
          className='sidebar-menu-2 py-2 pl-2 pr-3'
          // onClick={(e) => (i.type === '' ? i.action() : e.preventDefault())}
        >
          <div
            className='flex items-center parents'
            onClick={() => {
              i.subItems
                ? menuSelected === index
                  ? setMenuSelected(null)
                  : setMenuSelected(index)
                : i.action();
            }}
          >
            <div className='inline-block leading-none flex items-center' style={{ width: 28, height: 28 }}>
              <Image src={i.icon} alt={i.label} placeholder='empty' />
            </div>

            <div
              className={`inline-block ml-2 leading-none ${i.subItems ? 'w-3/5' : 'w-4/5'
              }`}
            >
              <div className='flex flex-col items-start'>
                <span className='font-normal capitalize mr-1'>
                  {i.label}
                </span>
                {i.labelLanguage && (
                  <div className='text-gray-500 capitalize'>
                    {i.labelLanguage}
                  </div>
                )}
                <span className='font-normal capitalize mr-1'>
                  {i.text}
                </span>
                {/* {i.labelLanguage && (
                                  <div className='inline-block w-7 mt-1'>
                                    {i.labelLanguage}
                                  </div>
                                )} */}
              </div>
            </div>

            {/* <div className='inline-block w-1/5 text-right'>
                              <div className='leading-none ml-auto' style={{ width: 7 }}>
                                <Image   src={MenuChevronLeft} alt='' />
                              </div>
                            </div> */}

            {i.subItems && (
              <div className='inline-block w-1/5'>
                <div className='text-end '>
                  {menuSelected !== index && (
                    <span>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='12'
                        height='12'
                        fill='currentColor'
                        className='bi bi-chevron-down'
                        style={{ display: 'initial' }}
                        viewBox='0 0 16 16'
                      >
                        <path
                          fillRule='evenodd'
                          d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'
                        />
                      </svg>
                    </span>
                  )}
                  {menuSelected === index && (
                    <span>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='12'
                        height='12'
                        fill='currentColor'
                        className='bi bi-chevron-up'
                        style={{ display: 'initial' }}
                        viewBox='0 0 16 16'
                      >
                        <path
                          fillRule='evenodd'
                          d='M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z'
                        />
                      </svg>
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>

          {(i.subItems?.length != 0) & (menuSelected === index) ? (
            <div className='child'>
              {i.subItems && (
                <>
                  {i.subItems.map((item, index) => (
                    <>
                      <div className='inline-block w-1/5' />
                      <div
                        className='inline-block mt-2 w-4/5 pl-1'
                        onClick={item.action}
                      >
                        {item.label}
                      </div>
                    </>
                  ))}
                </>
              )}
            </div>
          ) : (
            ''
          )}
        </div>
      ))}
    </div>
  );
}

export default SideMenu;
