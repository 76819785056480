const axios = require('axios');
const { ENDPOINT } = require('./constants');

const SendVerifyCode = (data) => axios.request({
  method: 'POST',
  url: `${ENDPOINT}/v1/sendVerifyCode`,
  headers: { 'Content-Type': 'application/json' },
  data,
})
  .then((response) => response)
  .catch((error) => ({ error: error?.response?.data }));

module.exports = {
  SendVerifyCode,
};
