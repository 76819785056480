export const routes = {
  home: {
    to: () => '/',
  },
  login: {
    to: () => '/login',
  },
  register: {
    to: () => '/register',
  },
  forgotpassword: {
    to: () => '/forgot_password',
  },
  helpdesk: {
    to: () => '/helpdesk',
  },
  contact: {
    to: () => '/contact',
  },
  contactDetail: {
    to: (id) => `/contact_detail/${id}`,
  },
  transactions: {
    to: () => '/transactions',
  },
  language: {
    to: () => '/language',
  },
  transactions: {
    to: () => '/transactions',
  },
  games_filter: {
    to: () => '/games_filter',
  },
  profile: {
    to: () => '/profile',
  },
  bankAccount: {
    to: () => '/bank_account',
  },
  addLocalBank: {
    to: () => '/add_bank_account',
  },
  editLocalBank: {
    to: (id) => `/edit_bank_account/${id}`,
  },
  addCryptoBank: {
    to: () => '/add_crypto_account',
  },
  changePassword: {
    to: () => '/change_password',
  },
  walletInformation: {
    to: () => '/wallet_information',
  },
  topUpUsdt: {
    to: () => '/top_up_usdt',
  },
  topUp: {
    to: () => '/top_up',
  },
  withdraw: {
    to: () => '/withdraw',
  },
  promotion: {
    to: () => '/promotion',
  },
  inviteFriend: {
    to: () => '/referral',
  },
  downline: {
    to: () => '/downline',
  },
  changeWithdrawalPassword: {
    to: () => '/change_withdrawal_password',
  },
  editProfile: {
    to: () => '/edit_profile',
  },
  resetPassword: {
    to: () => '/reset_password',
  },
  faq: {
    to: () => '/faq',
  },
  vip: {
    to: () => '/vip',
  },
  liveStream: {
    to: () => '/liveStream',
  },
  gameWebsite: {
    to: () => '/game_website',
  },
  termsAndCondition: {
    to: () => '/terms_and_condition',
  },
  ipRestricted: {
    to: () => '/ip_restricted',
  },
};
