const axios = require('axios');
const Cookies = require('js-cookie');
const { ENDPOINT } = require('./constants');

const getBalance = () => axios
  .request({
    method: 'POST',
    url: `${ENDPOINT}/v1/getBalance`,
    headers: {
      Authorization: `Bearer ${
        Cookies.get('accessToken')
      }`,
    },
  })
  .then((response) => response)
  .catch((error) => error);

module.exports = {
  getBalance,
};
