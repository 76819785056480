const axios = require('axios');
const Cookies = require('js-cookie');
const { ENDPOINT } = require('../constants');

const getBonusClaim = (params) => axios
  .request({
    method: 'GET',
    url: `${ENDPOINT}/bonusclaims`,
    headers: {
      Authorization: `Bearer ${
        Cookies.get('accessToken') || params?.accessToken
      }`,
    },
    params,
  })
  .then((response) => response?.data)
  .catch((error) => error);

module.exports = {
  getBonusClaim,
};
