const axios = require('axios');
const Cookies = require('js-cookie');
const { ENDPOINT } = require('./constants');

const SendEmailVerifyCode = (data) => axios.request({
  method: 'POST',
  url: `${ENDPOINT}/v1/sendEmailVerifyCode`,
  headers: {
    'Content-Type': 'application/json',
  },
  data,
})
  .then((response) => response)
  .catch((error) => ({ error: error?.response?.data }));

const SendVerifyCode = (data) => axios.request({
  method: 'POST',
  url: `${ENDPOINT}/v1/sendVerifyCode`,
  headers: {
    'Content-Type': 'application/json',
  },
  data,
})
  .then((response) => response)
  .catch((error) => ({ error: error?.response?.data }));

const EditEmailSendVerifyCode = (data) => axios.request({
  method: 'POST',
  url: `${ENDPOINT}/v1/editEmailSendVerifyCode`,
  headers: {
    Authorization: `Bearer ${
      Cookies.get('accessToken') || params?.accessToken
    }`,
    'Content-Type': 'application/json',
  },
  data,
})
  .then((response) => response)
  .catch((error) => ({ error: error?.response?.data }));

const EditContactSendVerifyCode = (data) => axios.request({
  method: 'POST',
  url: `${ENDPOINT}/v1/editContactSendVerifyCode`,
  headers: {
    Authorization: `Bearer ${
      Cookies.get('accessToken') || params?.accessToken
    }`,
    'Content-Type': 'application/json',
  },
  data,
})
  .then((response) => response)
  .catch((error) => ({ error: error?.response?.data }));

const SubmitEmailVerifyCode = (data) => axios.request({
  method: 'POST',
  url: `${ENDPOINT}/v1/submitEmailVerifyCode`,
  headers: { 'Content-Type': 'application/json' },
  data,
})
  .then((response) => response)
  .catch((error) => ({ error: error?.response?.data }));

const CheckEmailVerified = (data) => axios.request({
  method: 'POST',
  url: `${ENDPOINT}/v1/checkEmailVerified`,
  headers: { 'Content-Type': 'application/json' },
  data,
})
  .then((response) => response)
  .catch((error) => ({ error: error?.response?.data }));

const SubmitPhoneVerifyCode = (data) => axios.request({
  method: 'POST',
  url: `${ENDPOINT}/v1/submitPhoneVerifyCode`,
  headers: { 'Content-Type': 'application/json' },
  data,
})
  .then((response) => response)
  .catch((error) => ({ error: error?.response?.data }));

const CheckPhoneVerified = (data) => axios.request({
  method: 'POST',
  url: `${ENDPOINT}/v1/checkPhoneVerified`,
  headers: { 'Content-Type': 'application/json' },
  data,
})
  .then((response) => response)
  .catch((error) => ({ error: error?.response?.data }));

const RegisterCheckEmailVerified = (data) => axios.request({
  method: 'POST',
  url: `${ENDPOINT}/v1/registerCheckEmailVerified`,
  headers: { 'Content-Type': 'application/json' },
  data,
})
  .then((response) => response)
  .catch((error) => ({ error: error?.response?.data }));

const ResetPassword = (data) => axios.request({
  method: 'POST',
  url: `${ENDPOINT}/v1/resetPassword`,
  headers: { 'Content-Type': 'application/json' },
  data,
})
  .then((response) => response)
  .catch((error) => ({ error: error?.response?.data }));

module.exports = {
  SendVerifyCode,
  SendEmailVerifyCode,
  EditEmailSendVerifyCode,
  SubmitEmailVerifyCode,
  CheckEmailVerified,
  CheckPhoneVerified,
  RegisterCheckEmailVerified,
  ResetPassword,
  SubmitPhoneVerifyCode,
  EditContactSendVerifyCode,
};
