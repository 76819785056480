import Image from 'next/image';

export const countiesCode = [{
  name: 'Afghanistan',
  dial_code: '+93',
  flag: <Image src='/flags/af.svg' width={25} height={15} alt={"Afghanistan flag"}/>,
  code: 'AF',
},
{
  name: 'Aland Islands',
  dial_code: '+358',
  flag: <Image src='/flags/ax.svg' width={25} height={15} alt={"Aland Islands flag"}/>,
  code: 'AX',
},
{
  name: 'Albania',
  dial_code: '+355',
  flag: <Image src='/flags/al.svg' width={25} height={15} alt={"Albania flag"}/>,
  code: 'AL',
},
{
  name: 'Algeria',
  dial_code: '+213',
  flag: <Image src='/flags/dz.svg' width={25} height={15} alt={"Algeria flag"}/>,
  code: 'DZ',
},
{
  name: 'AmericanSamoa',
  dial_code: '+1684',
  flag: <Image src='/flags/as.svg' width={25} height={15} alt={"AmericanSamoa flag"}/>,
  code: 'AS',
},
{
  name: 'Andorra',
  dial_code: '+376',
  flag: <Image src='/flags/ad.svg' width={25} height={15} alt={"Andorra flag"}/>,
  code: 'AD',
},
{
  name: 'Angola',
  dial_code: '+244',
  flag: <Image src='/flags/ao.svg' width={25} height={15} alt={"Angola flag"}/>,
  code: 'AO',
},
{
  name: 'Anguilla',
  dial_code: '+1264',
  flag: <Image src='/flags/ai.svg' width={25} height={15} alt={"Anguilla flag"}/>,
  code: 'AI',
},
{
  name: 'Antarctica',
  dial_code: '+672',
  flag: <Image src='/flags/aq.svg' width={25} height={15} alt={"Antarctica flag"}/>,
  code: 'AQ',
},
{
  name: 'Antigua and Barbuda',
  dial_code: '+1268',
  flag: <Image src='/flags/ag.svg' width={25} height={15} alt={"Antigua and Barbuda flag"}/>,
  code: 'AG',
},

{
  name: 'Argentina',
  dial_code: '+54',
  flag: <Image src='/flags/ar.svg' width={25} height={15} alt={"Argentina flag"}/>,
  code: 'AR',
},
{
  name: 'Armenia',
  dial_code: '+374',
  flag: <Image src='/flags/am.svg' width={25} height={15} alt={"Armenia flag"}/>,
  code: 'AM',
},
{
  name: 'Aruba',
  dial_code: '+297',
  flag: <Image src='/flags/aw.svg' width={25} height={15} alt={"Aruba flag"}/>,
  code: 'AW',
},
{
  name: 'Australia',
  dial_code: '+61',
  flag: <Image src='/flags/au.svg' width={25} height={15} alt={"Australia flag"}/>,
  code: 'AU',
},
{
  name: 'Austria',
  dial_code: '+43',
  flag: <Image src='/flags/at.svg' width={25} height={15} alt={"Austria flag"}/>,
  code: 'AT',
},
{
  name: 'Azerbaijan',
  dial_code: '+994',
  flag: <Image src='/flags/az.svg' width={25} height={15} alt={"Azerbaijan flag"}/>,
  code: 'AZ',
},
{
  name: 'Bahamas',
  dial_code: '+1242',
  flag: <Image src='/flags/bs.svg' width={25} height={15} alt={"Bahamas flag"}/>,
  code: 'BS',
},
{
  name: 'Bahrain',
  dial_code: '+973',
  flag: <Image src='/flags/bh.svg' width={25} height={15} alt={"Bahrain flag"}/>,
  code: 'BH',
},
{
  name: 'Bangladesh',
  dial_code: '+880',
  flag: <Image src='/flags/bd.svg' width={25} height={15} alt={"Bangladesh flag"}/>,
  code: 'BD',
},
{
  name: 'Barbados',
  dial_code: '+1246',
  flag: <Image src='/flags/bb.svg' width={25} height={15} alt={"Barbados flag"}/>,
  code: 'BB',
},
{
  name: 'Belarus',
  dial_code: '+375',
  flag: <Image src='/flags/by.svg' width={25} height={15} alt={"Belarus flag"}/>,
  code: 'BY',
},
{
  name: 'Belgium',
  dial_code: '+32',
  flag: <Image src='/flags/be.svg' width={25} height={15} alt={"Belgium flag"}/>,
  code: 'BE',
},
{
  name: 'Belize',
  dial_code: '+501',
  flag: <Image src='/flags/bz.svg' width={25} height={15} alt={"Belize flag"}/>,
  code: 'BZ',
},
{
  name: 'Benin',
  dial_code: '+229',
  flag: <Image src='/flags/bj.svg' width={25} height={15} alt={"Benin flag"}/>,
  code: 'BJ',
},
{
  name: 'Bermuda',
  dial_code: '+1441',
  flag: <Image src='/flags/bm.svg' width={25} height={15} alt={"Bermuda flag"}/>,
  code: 'BM',
},
{
  name: 'Bhutan',
  dial_code: '+975',
  flag: <Image src='/flags/bt.svg' width={25} height={15} alt={"Bhutan flag"}/>,
  code: 'BT',
},
{
  name: 'Bolivia, Plurinational State of',
  dial_code: '+591',
  flag: <Image src='/flags/bo.svg' width={25} height={15} alt={"Bolivia, Plurinational State of flag"}/>,
  code: 'BO',
},
{
  name: 'Bosnia and Herzegovina',
  dial_code: '+387',
  flag: <Image src='/flags/ba.svg' width={25} height={15} alt={"Bosnia and Herzegovina flag"}/>,
  code: 'BA',
},
{
  name: 'Botswana',
  dial_code: '+267',
  flag: <Image src='/flags/bw.svg' width={25} height={15} alt={"Botswana flag"}/>,
  code: 'BW',
},
{
  name: 'Brazil',
  dial_code: '+55',
  flag: <Image src='/flags/br.svg' width={25} height={15} alt={"Brazil flag"}/>,
  code: 'BR',
},
{
  name: 'British Indian Ocean Territory',
  dial_code: '+246',
  flag: <Image src='/flags/io.svg' width={25} height={15} alt={"British Indian Ocean Territory flag"}/>,
  code: 'IO',
},
{
  name: 'Brunei Darussalam',
  dial_code: '+673',
  flag: <Image src='/flags/bn.svg' width={25} height={15} alt={"Brunei Darussalam flag"}/>,
  code: 'BN',
},
{
  name: 'Bulgaria',
  dial_code: '+359',
  flag: <Image src='/flags/bg.svg' width={25} height={15} alt={"Bulgaria flag"}/>,
  code: 'BG',
},
{
  name: 'Burkina Faso',
  dial_code: '+226',
  flag: <Image src='/flags/bf.svg' width={25} height={15} alt={"Burkina Faso flag"}/>,
  code: 'BF',
},
{
  name: 'Burundi',
  dial_code: '+257',
  flag: <Image src='/flags/bi.svg' width={25} height={15} alt={"Burundi flag"}/>,
  code: 'BI',
},
{
  name: 'Cambodia',
  dial_code: '+855',
  flag: <Image src='/flags/kh.svg' width={25} height={15} alt={"Cambodia flag"}/>,
  code: 'KH',
},
{
  name: 'Cameroon',
  dial_code: '+237',
  flag: <Image src='/flags/cm.svg' width={25} height={15} alt={"Cameroon flag"}/>,
  code: 'CM',
},
{
  name: 'Canada',
  dial_code: '+1',
  flag: <Image src='/flags/ca.svg' width={25} height={15} alt={"Canada flag"}/>,
  code: 'CA',
},
{
  name: 'Cape Verde',
  dial_code: '+238',
  flag: <Image src='/flags/cv.svg' width={25} height={15} alt={"Cape Verde flag"}/>,
  code: 'CV',
},
{
  name: 'Cayman Islands',
  dial_code: '+345',
  flag: <Image src='/flags/ky.svg' width={25} height={15} alt={"Cayman Islands flag"}/>,
  code: 'KY',
},
{
  name: 'Central African Republic',
  dial_code: '+236',
  flag: <Image src='/flags/cf.svg' width={25} height={15} alt={"Central African Republic flag"}/>,
  code: 'CF',
},
{
  name: 'Chad',
  dial_code: '+235',
  flag: <Image src='/flags/td.svg' width={25} height={15} alt={"Chad flag"}/>,
  code: 'TD',
},
{
  name: 'Chile',
  dial_code: '+56',
  flag: <Image src='/flags/cl.svg' width={25} height={15} alt={"Chile flag"}/>,
  code: 'CL',
},
{
  name: 'China',
  dial_code: '+86',
  flag: <Image src='/flags/cn.svg' width={25} height={15} alt={"China flag"}/>,
  code: 'CN',
},
{
  name: 'Christmas Island',
  dial_code: '+61',
  flag: <Image src='/flags/cx.svg' width={25} height={15} alt={"Christmas Island flag"}/>,
  code: 'CX',
},
{
  name: 'Cocos (Keeling) Islands',
  dial_code: '+61',
  flag: <Image src='/flags/cc.svg' width={25} height={15} alt={"Cocos (Keeling) Islands flag"}/>,
  code: 'CC',
},
{
  name: 'Colombia',
  dial_code: '+57',
  flag: <Image src='/flags/co.svg' width={25} height={15} alt={"Colombia flag"}/>,
  code: 'CO',
},
{
  name: 'Comoros',
  dial_code: '+269',
  flag: <Image src='/flags/km.svg' width={25} height={15} alt={"Comoros flag"}/>,
  code: 'KM',
},
{
  name: 'Congo',
  dial_code: '+242',
  flag: <Image src='/flags/cg.svg' width={25} height={15} alt={"Congo flag"}/>,
  code: 'CG',
},
{
  name: 'Congo, The Democratic Republic of the Congo',
  dial_code: '+243',
  flag: <Image src='/flags/cd.svg' width={25} height={15} alt={"Congo, The Democratic Republic of the Congo flag"}/>,
  code: 'CD',
},
{
  name: 'Cook Islands',
  dial_code: '+682',
  flag: <Image src='/flags/ck.svg' width={25} height={15} alt={"Cook Islands flag"}/>,
  code: 'CK',
},
{
  name: 'Costa Rica',
  dial_code: '+506',
  flag: <Image src='/flags/cr.svg' width={25} height={15} alt={"Costa Rica flag"}/>,
  code: 'CR',
},
{
  name: 'Cote d\'Ivoire',
  dial_code: '+225',
  flag: <Image src='/flags/ci.svg' width={25} height={15} alt={"Cote d\'Ivoire flag"}/>,
  code: 'CI',
},
{
  name: 'Croatia',
  dial_code: '+385',
  flag: <Image src='/flags/hr.svg' width={25} height={15} alt={"Croatia flag"}/>,
  code: 'HR',
},
{
  name: 'Cuba',
  dial_code: '+53',
  flag: <Image src='/flags/cu.svg' width={25} height={15} alt={"Cuba flag"}/>,
  code: 'CU',
},
{
  name: 'Cyprus',
  dial_code: '+357',
  flag: <Image src='/flags/cy.svg' width={25} height={15} alt={"Cyprus flag"}/>,
  code: 'CY',
},
{
  name: 'Czech Republic',
  dial_code: '+420',
  flag: <Image src='/flags/cz.svg' width={25} height={15} alt={"Czech Republic flag"}/>,
  code: 'CZ',
},
{
  name: 'Denmark',
  dial_code: '+45',
  flag: <Image src='/flags/dk.svg' width={25} height={15} alt={"Denmark flag"}/>,
  code: 'DK',
},
{
  name: 'Djibouti',
  dial_code: '+253',
  flag: <Image src='/flags/dj.svg' width={25} height={15} alt={"Djibouti flag"}/>,
  code: 'DJ',
},
{
  name: 'Dominica',
  dial_code: '+1767',
  flag: <Image src='/flags/dm.svg' width={25} height={15} alt={"Dominica flag"}/>,
  code: 'DM',
},
{
  name: 'Dominican Republic',
  dial_code: '+1849',
  flag: <Image src='/flags/do.svg' width={25} height={15} alt={"Dominican Republic flag"}/>,
  code: 'DO',
},
{
  name: 'Ecuador',
  dial_code: '+593',
  flag: <Image src='/flags/ec.svg' width={25} height={15} alt={"Ecuador flag"}/>,
  code: 'EC',
},
{
  name: 'Egypt',
  dial_code: '+20',
  flag: <Image src='/flags/eg.svg' width={25} height={15} alt={"Egypt flag"}/>,
  code: 'EG',
},
{
  name: 'El Salvador',
  dial_code: '+503',
  flag: <Image src='/flags/sv.svg' width={25} height={15} alt={"El Salvador flag"}/>,
  code: 'SV',
},
{
  name: 'Equatorial Guinea',
  dial_code: '+240',
  flag: <Image src='/flags/gq.svg' width={25} height={15} alt={"Equatorial Guinea flag"}/>,
  code: 'GQ',
},
{
  name: 'Eritrea',
  dial_code: '+291',
  flag: <Image src='/flags/er.svg' width={25} height={15} alt={"Eritrea flag"}/>,
  code: 'ER',
},
{
  name: 'Estonia',
  dial_code: '+372',
  flag: <Image src='/flags/ee.svg' width={25} height={15} alt={"Estonia flag"}/>,
  code: 'EE',
},
{
  name: 'Ethiopia',
  dial_code: '+251',
  flag: <Image src='/flags/et.svg' width={25} height={15} alt={"Ethiopia flag"}/>,
  code: 'ET',
},
{
  name: 'Falkland Islands (Malvinas)',
  dial_code: '+500',
  flag: <Image src='/flags/fk.svg' width={25} height={15} alt={"Falkland Islands (Malvinas) flag"}/>,
  code: 'FK',
},
{
  name: 'Faroe Islands',
  dial_code: '+298',
  flag: <Image src='/flags/fo.svg' width={25} height={15} alt={"Faroe Islands flag"}/>,
  code: 'FO',
},
{
  name: 'Fiji',
  dial_code: '+679',
  flag: <Image src='/flags/fj.svg' width={25} height={15} alt={"Fiji flag"}/>,
  code: 'FJ',
},
{
  name: 'Finland',
  dial_code: '+358',
  flag: <Image src='/flags/fi.svg' width={25} height={15} alt={"Finland flag"}/>,
  code: 'FI',
},
{
  name: 'France',
  dial_code: '+33',
  flag: <Image src='/flags/fr.svg' width={25} height={15} alt={"France flag"}/>,
  code: 'FR',
},
{
  name: 'French Guiana',
  dial_code: '+594',
  flag: <Image src='/flags/gf.svg' width={25} height={15} alt={"French Guiana flag"}/>,
  code: 'GF',
},
{
  name: 'French Polynesia',
  dial_code: '+689',
  flag: <Image src='/flags/pf.svg' width={25} height={15} alt={"French Polynesia flag"}/>,
  code: 'PF',
},
{
  name: 'Gabon',
  dial_code: '+241',
  flag: <Image src='/flags/ga.svg' width={25} height={15} alt={"Gabon flag"}/>,
  code: 'GA',
},
{
  name: 'Gambia',
  dial_code: '+220',
  flag: <Image src='/flags/gm.svg' width={25} height={15} alt={"Gambia flag"}/>,
  code: 'GM',
},
{
  name: 'Georgia',
  dial_code: '+995',
  flag: <Image src='/flags/ge.svg' width={25} height={15} alt={"Georgia flag"}/>,
  code: 'GE',
},
{
  name: 'Germany',
  dial_code: '+49',
  flag: <Image src='/flags/de.svg' width={25} height={15} alt={"Germany flag"}/>,
  code: 'DE',
},
{
  name: 'Ghana',
  dial_code: '+233',
  flag: <Image src='/flags/gh.svg' width={25} height={15} alt={"Ghana flag"}/>,
  code: 'GH',
},
{
  name: 'Gibraltar',
  dial_code: '+350',
  flag: <Image src='/flags/gi.svg' width={25} height={15} alt={"Gibraltar flag"}/>,
  code: 'GI',
},
{
  name: 'Greece',
  dial_code: '+30',
  flag: <Image src='/flags/gr.svg' width={25} height={15} alt={"Greece flag"}/>,
  code: 'GR',
},
{
  name: 'Greenland',
  dial_code: '+299',
  flag: <Image src='/flags/gl.svg' width={25} height={15} alt={"Greenland flag"}/>,
  code: 'GL',
},
{
  name: 'Grenada',
  dial_code: '+1473',
  flag: <Image src='/flags/gd.svg' width={25} height={15} alt={"Grenada flag"}/>,
  code: 'GD',
},
{
  name: 'Guadeloupe',
  dial_code: '+590',
  flag: <Image src='/flags/gp.svg' width={25} height={15} alt={"Guadeloupe flag"}/>,
  code: 'GP',
},
{
  name: 'Guam',
  dial_code: '+1671',
  flag: <Image src='/flags/gu.svg' width={25} height={15} alt={"Guam flag"}/>,
  code: 'GU',
},
{
  name: 'Guatemala',
  dial_code: '+502',
  flag: <Image src='/flags/gt.svg' width={25} height={15} alt={"Guatemala flag"}/>,
  code: 'GT',
},
{
  name: 'Guernsey',
  dial_code: '+44',
  flag: <Image src='/flags/gg.svg' width={25} height={15} alt={"Guernsey flag"}/>,
  code: 'GG',
},
{
  name: 'Guinea',
  dial_code: '+224',
  flag: <Image src='/flags/gn.svg' width={25} height={15} alt={"Guinea flag"}/>,
  code: 'GN',
},
{
  name: 'Guinea-Bissau',
  dial_code: '+245',
  flag: <Image src='/flags/gw.svg' width={25} height={15} alt={"Guinea-Bissau flag"}/>,
  code: 'GW',
},
{
  name: 'Guyana',
  dial_code: '+595',
  flag: <Image src='/flags/gy.svg' width={25} height={15} alt={"Guyana flag"}/>,
  code: 'GY',
},
{
  name: 'Haiti',
  dial_code: '+509',
  flag: <Image src='/flags/ht.svg' width={25} height={15} alt={"Haiti flag"}/>,
  code: 'HT',
},
{
  name: 'Holy See (Vatican City State)',
  dial_code: '+379',
  flag: <Image src='/flags/va.svg' width={25} height={15} alt={"Holy See (Vatican City State) flag"}/>,
  code: 'VA',
},
{
  name: 'Honduras',
  dial_code: '+504',
  flag: <Image src='/flags/hn.svg' width={25} height={15} alt={"Honduras flag"}/>,
  code: 'HN',
},
{
  name: 'Hong Kong',
  dial_code: '+852',
  flag: <Image src='/flags/hk.svg' width={25} height={15} alt={"Hong Kong flag"}/>,
  code: 'HK',
},
{
  name: 'Hungary',
  dial_code: '+36',
  flag: <Image src='/flags/hu.svg' width={25} height={15} alt={"Hungary flag"}/>,
  code: 'HU',
},
{
  name: 'Iceland',
  dial_code: '+354',
  flag: <Image src='/flags/is.svg' width={25} height={15} alt={"Iceland flag"}/>,
  code: 'IS',
},
{
  name: 'India',
  dial_code: '+91',
  flag: <Image src='/flags/in.svg' width={25} height={15} alt={"India flag"}/>,
  code: 'IN',
},
{
  name: 'Indonesia',
  dial_code: '+62',
  flag: <Image src='/flags/id.svg' width={25} height={15} alt={"Indonesia flag"}/>,
  code: 'ID',
},
{
  name: 'Iran, Islamic Republic of Persian Gulf',
  dial_code: '+98',
  flag: <Image src='/flags/ir.svg' width={25} height={15} alt={"Iran, Islamic Republic of Persian Gulf flag"}/>,
  code: 'IR',
},
{
  name: 'Iraq',
  dial_code: '+964',
  flag: <Image src='/flags/iq.svg' width={25} height={15} alt={"Iraq flag"}/>,
  code: 'IQ',
},
{
  name: 'Ireland',
  dial_code: '+353',
  flag: <Image src='/flags/ie.svg' width={25} height={15} alt={"Ireland flag"}/>,
  code: 'IE',
},
{
  name: 'Isle of Man',
  dial_code: '+44',
  flag: <Image src='/flags/im.svg' width={25} height={15} alt={"Isle of Man flag"}/>,
  code: 'IM',
},
{
  name: 'Israel',
  dial_code: '+972',
  flag: <Image src='/flags/il.svg' width={25} height={15} alt={"Israel flag"}/>,
  code: 'IL',
},
{
  name: 'Italy',
  dial_code: '+39',
  flag: <Image src='/flags/it.svg' width={25} height={15} alt={"Italy flag"}/>,
  code: 'IT',
},
{
  name: 'Jamaica',
  dial_code: '+1876',
  flag: <Image src='/flags/jm.svg' width={25} height={15} alt={"Jamaica flag"}/>,
  code: 'JM',
},
{
  name: 'Japan',
  dial_code: '+81',
  flag: <Image src='/flags/jp.svg' width={25} height={15} alt={"Japan flag"}/>,
  code: 'JP',
},
{
  name: 'Jersey',
  dial_code: '+44',
  flag: <Image src='/flags/je.svg' width={25} height={15} alt={"Jersey flag"}/>,
  code: 'JE',
},
{
  name: 'Jordan',
  dial_code: '+962',
  flag: <Image src='/flags/jo.svg' width={25} height={15} alt={"Jordan flag"}/>,
  code: 'JO',
},
{
  name: 'Kazakhstan',
  dial_code: '+77',
  flag: <Image src='/flags/kz.svg' width={25} height={15} alt={"Kazakhstan flag"}/>,
  code: 'KZ',
},
{
  name: 'Kenya',
  dial_code: '+254',
  flag: <Image src='/flags/ke.svg' width={25} height={15} alt={"Kenya flag"}/>,
  code: 'KE',
},
{
  name: 'Kiribati',
  dial_code: '+686',
  flag: <Image src='/flags/ki.svg' width={25} height={15} alt={"Kiribati flag"}/>,
  code: 'KI',
},
{
  name: 'Korea, Democratic People\'s Republic of Korea',
  dial_code: '+850',
  flag: <Image src='/flags/kp.svg' width={25} height={15} alt={"Korea, Democratic People\'s Republic of Korea flag"}/>,
  code: 'KP',
},
{
  name: 'Korea, Republic of South Korea',
  dial_code: '+82',
  flag: <Image src='/flags/kr.svg' width={25} height={15} alt={"Korea, Republic of South Korea flag"}/>,
  code: 'KR',
},
{
  name: 'Kuwait',
  dial_code: '+965',
  flag: <Image src='/flags/kw.svg' width={25} height={15} alt={"Kuwait flag"}/>,
  code: 'KW',
},
{
  name: 'Kyrgyzstan',
  dial_code: '+996',
  flag: <Image src='/flags/kg.svg' width={25} height={15} alt={"Kyrgyzstan flag"}/>,
  code: 'KG',
},
{
  name: 'Laos',
  dial_code: '+856',
  flag: <Image src='/flags/la.svg' width={25} height={15} alt={"Laos flag"}/>,
  code: 'LA',
},
{
  name: 'Latvia',
  dial_code: '+371',
  flag: <Image src='/flags/lv.svg' width={25} height={15} alt={"Latvia flag"}/>,
  code: 'LV',
},
{
  name: 'Lebanon',
  dial_code: '+961',
  flag: <Image src='/flags/lb.svg' width={25} height={15} alt={"Lebanon flag"}/>,
  code: 'LB',
},
{
  name: 'Lesotho',
  dial_code: '+266',
  flag: <Image src='/flags/ls.svg' width={25} height={15} alt={"Lesotho flag"}/>,
  code: 'LS',
},
{
  name: 'Liberia',
  dial_code: '+231',
  flag: <Image src='/flags/lr.svg' width={25} height={15} alt={"Liberia flag"}/>,
  code: 'LR',
},
{
  name: 'Libyan Arab Jamahiriya',
  dial_code: '+218',
  flag: <Image src='/flags/ly.svg' width={25} height={15} alt={"Libyan Arab Jamahiriya flag"}/>,
  code: 'LY',
},
{
  name: 'Liechtenstein',
  dial_code: '+423',
  flag: <Image src='/flags/li.svg' width={25} height={15} alt={"Liechtenstein flag"}/>,
  code: 'LI',
},
{
  name: 'Lithuania',
  dial_code: '+370',
  flag: <Image src='/flags/lt.svg' width={25} height={15} alt={"Lithuania flag"}/>,
  code: 'LT',
},
{
  name: 'Luxembourg',
  dial_code: '+352',
  flag: <Image src='/flags/lu.svg' width={25} height={15} alt={"Luxembourg flag"}/>,
  code: 'LU',
},
{
  name: 'Macao',
  dial_code: '+853',
  flag: <Image src='/flags/mo.svg' width={25} height={15} alt={"Macao flag"}/>,
  code: 'MO',
},
{
  name: 'Macedonia',
  dial_code: '+389',
  flag: <Image src='/flags/mk.svg' width={25} height={15} alt={"Macedonia flag"}/>,
  code: 'MK',
},
{
  name: 'Madagascar',
  dial_code: '+261',
  flag: <Image src='/flags/mg.svg' width={25} height={15} alt={"Madagascar flag"}/>,
  code: 'MG',
},
{
  name: 'Malawi',
  dial_code: '+265',
  flag: <Image src='/flags/mw.svg' width={25} height={15} alt={"Malawi flag"}/>,
  code: 'MW',
},
{
  name: 'Malaysia',
  dial_code: '+60',
  flag: <Image src='/flags/my.svg' width={25} height={15} alt={"Malaysia flag"}/>,
  code: 'MY',
},
{
  name: 'Maldives',
  dial_code: '+960',
  flag: <Image src='/flags/mv.svg' width={25} height={15} alt={"Maldives flag"}/>,
  code: 'MV',
},
{
  name: 'Mali',
  dial_code: '+223',
  flag: <Image src='/flags/ml.svg' width={25} height={15} alt={"Mali flag"}/>,
  code: 'ML',
},
{
  name: 'Malta',
  dial_code: '+356',
  flag: <Image src='/flags/mt.svg' width={25} height={15} alt={"Malta flag"}/>,
  code: 'MT',
},
{
  name: 'Marshall Islands',
  dial_code: '+692',
  flag: <Image src='/flags/mh.svg' width={25} height={15} alt={"Marshall Islands flag"}/>,
  code: 'MH',
},
{
  name: 'Martinique',
  dial_code: '+596',
  flag: <Image src='/flags/mq.svg' width={25} height={15} alt={"Martinique flag"}/>,
  code: 'MQ',
},
{
  name: 'Mauritania',
  dial_code: '+222',
  flag: <Image src='/flags/mr.svg' width={25} height={15} alt={"Mauritania flag"}/>,
  code: 'MR',
},
{
  name: 'Mauritius',
  dial_code: '+230',
  flag: <Image src='/flags/mu.svg' width={25} height={15} alt={"Mauritius flag"}/>,
  code: 'MU',
},
{
  name: 'Mayotte',
  dial_code: '+262',
  flag: <Image src='/flags/yt.svg' width={25} height={15} alt={"Mayotte flag"}/>,
  code: 'YT',
},
{
  name: 'Mexico',
  dial_code: '+52',
  flag: <Image src='/flags/mx.svg' width={25} height={15} alt={"Mexico flag"}/>,
  code: 'MX',
},
{
  name: 'Micronesia, Federated States of Micronesia',
  dial_code: '+691',
  flag: <Image src='/flags/fm.svg' width={25} height={15} alt={"Micronesia, Federated States of Micronesia flag"}/>,
  code: 'FM',
},
{
  name: 'Moldova',
  dial_code: '+373',
  flag: <Image src='/flags/md.svg' width={25} height={15} alt={"Moldova flag"}/>,
  code: 'MD',
},
{
  name: 'Monaco',
  dial_code: '+377',
  flag: <Image src='/flags/mc.svg' width={25} height={15} alt={"Monaco flag"}/>,
  code: 'MC',
},
{
  name: 'Mongolia',
  dial_code: '+976',
  flag: <Image src='/flags/mn.svg' width={25} height={15} alt={"Mongolia flag"}/>,
  code: 'MN',
},
{
  name: 'Montenegro',
  dial_code: '+382',
  flag: <Image src='/flags/me.svg' width={25} height={15} alt={"Montenegro flag"}/>,
  code: 'ME',
},
{
  name: 'Montserrat',
  dial_code: '+1664',
  flag: <Image src='/flags/ms.svg' width={25} height={15} alt={"Montserrat flag"}/>,
  code: 'MS',
},
{
  name: 'Morocco',
  dial_code: '+212',
  flag: <Image src='/flags/ma.svg' width={25} height={15} alt={"Morocco flag"}/>,
  code: 'MA',
},
{
  name: 'Mozambique',
  dial_code: '+258',
  flag: <Image src='/flags/mz.svg' width={25} height={15} alt={"Mozambique flag"}/>,
  code: 'MZ',
},
{
  name: 'Myanmar',
  dial_code: '+95',
  flag: <Image src='/flags/mm.svg' width={25} height={15} alt={"Myanmar flag"}/>,
  code: 'MM',
},
{
  name: 'Namibia',
  flag: <Image src='/flags/na.svg' width={25} height={15} alt={"Namibia flag"}/>,
  dial_code: '+264',
  code: 'NA',
},
{
  name: 'Nauru',
  dial_code: '+674',
  flag: <Image src='/flags/nr.svg' width={25} height={15} alt={"Nauru flag"}/>,
  code: 'NR',
},
{
  name: 'Nepal',
  dial_code: '+977',
  flag: <Image src='/flags/np.svg' width={25} height={15} alt={"Nepal flag"}/>,
  code: 'NP',
},
{
  name: 'Netherlands',
  dial_code: '+31',
  flag: <Image src='/flags/nl.svg' width={25} height={15} alt={"Netherlands flag"}/>,
  code: 'NL',
},
{
  name: 'Netherlands Antilles',
  dial_code: '+599',
  flag: <Image src='/flags/bq.svg' width={25} height={15} alt={"Netherlands Antilles flag"}/>,
  code: 'AN',
},
{
  name: 'New Caledonia',
  dial_code: '+687',
  flag: <Image src='/flags/nc.svg' width={25} height={15} alt={"New Caledonia flag"}/>,
  code: 'NC',
},
{
  name: 'New Zealand',
  dial_code: '+64',
  flag: <Image src='/flags/nz.svg' width={25} height={15} alt={"New Zealand flag"}/>,
  code: 'NZ',
},
{
  name: 'Nicaragua',
  dial_code: '+505',
  flag: <Image src='/flags/ni.svg' width={25} height={15} alt={"Nicaragua flag"}/>,
  code: 'NI',
},
{
  name: 'Niger',
  dial_code: '+227',
  flag: <Image src='/flags/ne.svg' width={25} height={15} alt={"Niger flag"}/>,
  code: 'NE',
},
{
  name: 'Nigeria',
  dial_code: '+234',
  flag: <Image src='/flags/ng.svg' width={25} height={15} alt={"Nigeria flag"}/>,
  code: 'NG',
},
{
  name: 'Niue',
  dial_code: '+683',
  flag: <Image src='/flags/nu.svg' width={25} height={15} alt={"Niue flag"}/>,
  code: 'NU',
},
{
  name: 'Norfolk Island',
  dial_code: '+672',
  flag: <Image src='/flags/nf.svg' width={25} height={15} alt={"Norfolk Island flag"}/>,
  code: 'NF',
},
{
  name: 'Northern Mariana Islands',
  dial_code: '+1670',
  flag: <Image src='/flags/mp.svg' width={25} height={15} alt={"Northern Mariana Islands flag"}/>,
  code: 'MP',
},
{
  name: 'Norway',
  dial_code: '+47',
  flag: <Image src='/flags/no.svg' width={25} height={15} alt={"Norway flag"}/>,
  code: 'NO',
},
{
  name: 'Oman',
  dial_code: '+968',
  flag: <Image src='/flags/om.svg' width={25} height={15} alt={"Oman flag"}/>,
  code: 'OM',
},
{
  name: 'Pakistan',
  dial_code: '+92',
  flag: <Image src='/flags/pk.svg' width={25} height={15} alt={"Pakistan flag"}/>,
  code: 'PK',
},
{
  name: 'Palau',
  dial_code: '+680',
  flag: <Image src='/flags/pw.svg' width={25} height={15} alt={"Palau flag"}/>,
  code: 'PW',
},
{
  name: 'Palestinian Territory, Occupied',
  dial_code: '+970',
  flag: <Image src='/flags/ps.svg' width={25} height={15} alt={"Palestinian Territory, Occupied flag"}/>,
  code: 'PS',
},
{
  name: 'Panama',
  dial_code: '+507',
  flag: <Image src='/flags/pa.svg' width={25} height={15} alt={"Panama flag"}/>,
  code: 'PA',
},
{
  name: 'Papua New Guinea',
  dial_code: '+675',
  flag: <Image src='/flags/pg.svg' width={25} height={15} alt={"Papua New Guinea flag"}/>,
  code: 'PG',
},
{
  name: 'Paraguay',
  dial_code: '+595',
  flag: <Image src='/flags/py.svg' width={25} height={15} alt={"Paraguay flag"}/>,
  code: 'PY',
},
{
  name: 'Peru',
  dial_code: '+51',
  flag: <Image src='/flags/pe.svg' width={25} height={15} alt={"Peru flag"}/>,
  code: 'PE',
},
{
  name: 'Philippines',
  dial_code: '+63',
  flag: <Image src='/flags/ph.svg' width={25} height={15} alt={"Philippines flag"}/>,
  code: 'PH',
},
{
  name: 'Pitcairn',
  dial_code: '+872',
  flag: <Image src='/flags/pn.svg' width={25} height={15} alt={"Pitcairn flag"}/>,
  code: 'PN',
},
{
  name: 'Poland',
  dial_code: '+48',
  flag: <Image src='/flags/pl.svg' width={25} height={15} alt={"Poland flag"}/>,
  code: 'PL',
},
{
  name: 'Portugal',
  dial_code: '+351',
  flag: <Image src='/flags/pt.svg' width={25} height={15} alt={"Portugal flag"}/>,
  code: 'PT',
},
{
  name: 'Puerto Rico',
  dial_code: '+1939',
  flag: <Image src='/flags/pr.svg' width={25} height={15} alt={"Puerto Rico flag"}/>,
  code: 'PR',
},
{
  name: 'Qatar',
  dial_code: '+974',
  flag: <Image src='/flags/qa.svg' width={25} height={15} alt={"Qatar flag"}/>,
  code: 'QA',
},
{
  name: 'Romania',
  dial_code: '+40',
  flag: <Image src='/flags/ro.svg' width={25} height={15} alt={"Romania flag"}/>,
  code: 'RO',
},
{
  name: 'Russia',
  dial_code: '+7',
  flag: <Image src='/flags/ru.svg' width={25} height={15} alt={"Russia flag"}/>,
  code: 'RU',
},
{
  name: 'Rwanda',
  dial_code: '+250',
  flag: <Image src='/flags/rw.svg' width={25} height={15} alt={"Rwanda flag"}/>,
  code: 'RW',
},
{
  name: 'Reunion',
  dial_code: '+262',
  flag: <Image src='/flags/re.svg' width={25} height={15} alt={"Reunion flag"}/>,
  code: 'RE',
},
{
  name: 'Saint Barthelemy',
  dial_code: '+590',
  flag: <Image src='/flags/bl.svg' width={25} height={15} alt={"Saint Barthelemy flag"}/>,
  code: 'BL',
},
{
  name: 'Saint Helena, Ascension and Tristan Da Cunha',
  dial_code: '+290',
  flag: <Image src='/flags/sh.svg' width={25} height={15} alt={"Saint Helena, Ascension and Tristan Da Cunha flag"}/>,
  code: 'SH',
},
{
  name: 'Saint Kitts and Nevis',
  dial_code: '+1869',
  flag: <Image src='/flags/kn.svg' width={25} height={15} alt={"Saint Kitts and Nevis flag"}/>,
  code: 'KN',
},
{
  name: 'Saint Lucia',
  dial_code: '+1758',
  flag: <Image src='/flags/lc.svg' width={25} height={15} alt={"Saint Lucia flag"}/>,
  code: 'LC',
},
{
  name: 'Saint Martin',
  dial_code: '+590',
  flag: <Image src='/flags/mf.svg' width={25} height={15} alt={"Saint Martin flag"}/>,
  code: 'MF',
},
{
  name: 'Saint Pierre and Miquelon',
  dial_code: '+508',
  flag: <Image src='/flags/pm.svg' width={25} height={15} alt={"Saint Pierre and Miquelon flag"}/>,
  code: 'PM',
},
{
  name: 'Saint Vincent and the Grenadines',
  dial_code: '+1784',
  flag: <Image src='/flags/vc.svg' width={25} height={15} alt={"Saint Vincent and the Grenadines flag"}/>,
  code: 'VC',
},
{
  name: 'Samoa',
  dial_code: '+685',
  flag: <Image src='/flags/ws.svg' width={25} height={15} alt={"Samoa flag"}/>,
  code: 'WS',
},
{
  name: 'San Marino',
  dial_code: '+378',
  flag: <Image src='/flags/sm.svg' width={25} height={15} alt={"San Marino flag"}/>,
  code: 'SM',
},
{
  name: 'Sao Tome and Principe',
  dial_code: '+239',
  flag: <Image src='/flags/st.svg' width={25} height={15} alt={"Sao Tome and Principe flag"}/>,
  code: 'ST',
},
{
  name: 'Saudi Arabia',
  dial_code: '+966',
  flag: <Image src='/flags/sa.svg' width={25} height={15} alt={"Saudi Arabia flag"}/>,
  code: 'SA',
},
{
  name: 'Senegal',
  dial_code: '+221',
  flag: <Image src='/flags/sn.svg' width={25} height={15} alt={"Senegal flag"}/>,
  code: 'SN',
},
{
  name: 'Serbia',
  dial_code: '+381',
  flag: <Image src='/flags/rs.svg' width={25} height={15} alt={"Serbia flag"}/>,
  code: 'RS',
},
{
  name: 'Seychelles',
  dial_code: '+248',
  flag: <Image src='/flags/sc.svg' width={25} height={15} alt={"Seychelles flag"}/>,
  code: 'SC',
},
{
  name: 'Sierra Leone',
  dial_code: '+232',
  flag: <Image src='/flags/sl.svg' width={25} height={15} alt={"Sierra Leone flag"}/>,
  code: 'SL',
},
{
  name: 'Singapore',
  dial_code: '+65',
  flag: <Image src='/flags/sg.svg' width={25} height={15} alt={"Singapore flag"}/>,
  code: 'SG',
},
{
  name: 'Slovakia',
  dial_code: '+421',
  flag: <Image src='/flags/sk.svg' width={25} height={15} alt={"Slovakia flag"}/>,
  code: 'SK',
},
{
  name: 'Slovenia',
  dial_code: '+386',
  flag: <Image src='/flags/si.svg' width={25} height={15} alt={"Slovenia flag"}/>,
  code: 'SI',
},
{
  name: 'Solomon Islands',
  dial_code: '+677',
  flag: <Image src='/flags/sb.svg' width={25} height={15} alt={"Solomon Islands flag"}/>,
  code: 'SB',
},
{
  name: 'Somalia',
  dial_code: '+252',
  flag: <Image src='/flags/so.svg' width={25} height={15} alt={"Somalia flag"}/>,
  code: 'SO',
},
{
  name: 'South Africa',
  dial_code: '+27',
  flag: <Image src='/flags/za.svg' width={25} height={15} alt={"South Africa flag"}/>,
  code: 'ZA',
},
{
  name: 'South Sudan',
  dial_code: '+211',
  flag: <Image src='/flags/ss.svg' width={25} height={15} alt={"South Sudan flag"}/>,
  code: 'SS',
},
{
  name: 'South Georgia and the South Sandwich Islands',
  dial_code: '+500',
  flag: <Image src='/flags/gs.svg' width={25} height={15} alt={"South Georgia and the South Sandwich Islands flag"}/>,
  code: 'GS',
},
{
  name: 'Spain',
  dial_code: '+34',
  flag: <Image src='/flags/es.svg' width={25} height={15} alt={"Spain flag"}/>,
  code: 'ES',
},
{
  name: 'Sri Lanka',
  dial_code: '+94',
  flag: <Image src='/flags/lk.svg' width={25} height={15} alt={"Sri Lanka flag"}/>,
  code: 'LK',
},
{
  name: 'Sudan',
  dial_code: '+249',
  flag: <Image src='/flags/sd.svg' width={25} height={15} alt={"Sudan flag"}/>,
  code: 'SD',
},
{
  name: 'Suriname',
  dial_code: '+597',
  flag: <Image src='/flags/sr.svg' width={25} height={15} alt={"Suriname flag"}/>,
  code: 'SR',
},
{
  name: 'Svalbard and Jan Mayen',
  dial_code: '+47',
  flag: <Image src='/flags/sj.svg' width={25} height={15} alt={"Svalbard and Jan Mayen flag"}/>,
  code: 'SJ',
},
{
  name: 'Swaziland',
  dial_code: '+268',
  flag: <Image src='/flags/sz.svg' width={25} height={15} alt={"Swaziland flag"}/>,
  code: 'SZ',
},
{
  name: 'Sweden',
  dial_code: '+46',
  flag: <Image src='/flags/se.svg' width={25} height={15} alt={"Sweden flag"}/>,
  code: 'SE',
},
{
  name: 'Switzerland',
  dial_code: '+41',
  flag: <Image src='/flags/ch.svg' width={25} height={15} alt={"Switzerland flag"}/>,
  code: 'CH',
},
{
  name: 'Syrian Arab Republic',
  dial_code: '+963',
  flag: <Image src='/flags/sy.svg' width={25} height={15} alt={"Syrian Arab Republic flag"}/>,
  code: 'SY',
},
{
  name: 'Taiwan',
  dial_code: '+886',
  flag: <Image src='/flags/tw.svg' width={25} height={15} alt={"Taiwan flag"}/>,
  code: 'TW',
},
{
  name: 'Tajikistan',
  dial_code: '+992',
  flag: <Image src='/flags/tj.svg' width={25} height={15} alt={"Tajikistan flag"}/>,
  code: 'TJ',
},
{
  name: 'Tanzania, United Republic of Tanzania',
  dial_code: '+255',
  flag: <Image src='/flags/tz.svg' width={25} height={15} alt={"Tanzania, United Republic of Tanzania flag"}/>,
  code: 'TZ',
},
{
  name: 'Thailand',
  dial_code: '+66',
  flag: <Image src='/flags/th.svg' width={25} height={15} alt={"Thailand flag"}/>,
  code: 'TH',
},
{
  name: 'Timor-Leste',
  dial_code: '+670',
  flag: <Image src='/flags/tl.svg' width={25} height={15} alt={"Timor-Leste flag"}/>,
  code: 'TL',
},
{
  name: 'Togo',
  dial_code: '+228',
  flag: <Image src='/flags/tg.svg' width={25} height={15} alt={"Togo flag"}/>,
  code: 'TG',
},
{
  name: 'Tokelau',
  dial_code: '+690',
  flag: <Image src='/flags/tk.svg' width={25} height={15} alt={"Tokelau flag"}/>,
  code: 'TK',
},
{
  name: 'Tonga',
  dial_code: '+676',
  flag: <Image src='/flags/to.svg' width={25} height={15} alt={"Tonga flag"}/>,
  code: 'TO',
},
{
  name: 'Trinidad and Tobago',
  dial_code: '+1868',
  flag: <Image src='/flags/tt.svg' width={25} height={15} alt={"Trinidad and Tobago flag"}/>,
  code: 'TT',
},
{
  name: 'Tunisia',
  dial_code: '+216',
  flag: <Image src='/flags/tn.svg' width={25} height={15} alt={"Tunisia flag"}/>,
  code: 'TN',
},
{
  name: 'Turkey',
  dial_code: '+90',
  flag: <Image src='/flags/tr.svg' width={25} height={15} alt={"Turkey flag"}/>,
  code: 'TR',
},
{
  name: 'Turkmenistan',
  dial_code: '+993',
  flag: <Image src='/flags/tm.svg' width={25} height={15} alt={"Turkmenistan flag"}/>,
  code: 'TM',
},
{
  name: 'Turks and Caicos Islands',
  dial_code: '+1649',
  flag: <Image src='/flags/tc.svg' width={25} height={15} alt={"Turks and Caicos Islands flag"}/>,
  code: 'TC',
},
{
  name: 'Tuvalu',
  dial_code: '+688',
  flag: <Image src='/flags/tv.svg' width={25} height={15} alt={"Tuvalu flag"}/>,
  code: 'TV',
},
{
  name: 'Uganda',
  dial_code: '+256',
  flag: <Image src='/flags/ug.svg' width={25} height={15} alt={"Uganda flag"}/>,
  code: 'UG',
},
{
  name: 'Ukraine',
  dial_code: '+380',
  flag: <Image src='/flags/ua.svg' width={25} height={15} alt={"Ukraine flag"}/>,
  code: 'UA',
},
{
  name: 'United Arab Emirates',
  dial_code: '+971',
  flag: <Image src='/flags/ae.svg' width={25} height={15} alt={"United Arab Emirates flag"}/>,
  code: 'AE',
},
{
  name: 'United Kingdom',
  dial_code: '+44',
  flag: <Image src='/flags/gb-eng.svg' width={25} height={15} alt={"United Kingdom flag"}/>,
  code: 'GB',
},
{
  name: 'United States',
  dial_code: '+1',
  flag: <Image src='/flags/us.svg' width={25} height={15} alt={"United States flag"}/>,
  code: 'US',
},
{
  name: 'Uruguay',
  dial_code: '+598',
  flag: <Image src='/flags/uy.svg' width={25} height={15} alt={"Uruguay flag"}/>,
  code: 'UY',
},
{
  name: 'Uzbekistan',
  dial_code: '+998',
  flag: <Image src='/flags/uz.svg' width={25} height={15} alt={"Uzbekistan flag"}/>,
  code: 'UZ',
},
{
  name: 'Vanuatu',
  dial_code: '+678',
  flag: <Image src='/flags/vu.svg' width={25} height={15} alt={"Vanuatu flag"}/>,
  code: 'VU',
},
{
  name: 'Venezuela, Bolivarian Republic of Venezuela',
  dial_code: '+58',
  flag: <Image src='/flags/ve.svg' width={25} height={15} alt={"Venezuela, Bolivarian Republic of Venezuela flag"}/>,
  code: 'VE',
},
{
  name: 'Vietnam',
  dial_code: '+84',
  flag: <Image src='/flags/vn.svg' width={25} height={15} alt={"Vietnam flag"}/>,
  code: 'VN',
},
{
  name: 'Virgin Islands, British',
  dial_code: '+1284',
  flag: <Image src='/flags/vg.svg' width={25} height={15} alt={"Virgin Islands, British flag"}/>,
  code: 'VG',
},
{
  name: 'Virgin Islands, U.S.',
  dial_code: '+1340',
  flag: <Image src='/flags/vi.svg' width={25} height={15} alt={"Virgin Islands, U.S. flag"}/>,
  code: 'VI',
},
{
  name: 'Wallis and Futuna',
  dial_code: '+681',
  flag: <Image src='/flags/wf.svg' width={25} height={15} alt={"Wallis and Futuna flag"}/>,
  code: 'WF',
},
{
  name: 'Yemen',
  dial_code: '+967',
  flag: <Image src='/flags/ye.svg' width={25} height={15} alt={"Yemen flag"}/>,
  code: 'YE',
},
{
  name: 'Zambia',
  dial_code: '+260',
  flag: <Image src='/flags/zm.svg' width={25} height={15} alt={"Zambia flag"}/>,
  code: 'ZM',
},
{
  name: 'Zimbabwe',
  dial_code: '+263',
  flag: <Image src='/flags/zw.svg' width={25} height={15} alt={"Zimbabwe flag"}/>,
  code: 'ZW',
},
];
