const axios = require('axios');
const Cookies = require('js-cookie');
const { ENDPOINT } = require('./constants');

const User = (data) => axios
  .get(`${ENDPOINT}/users/${data?._id}`, {
    headers: {
      Authorization: `Bearer ${
        Cookies.get('accessToken') || data?.accessToken
      }`,
    },
  })
  .then((response) => response?.data)
  .catch((error) => console.log({ error }));

module.exports = {
  User,
};
