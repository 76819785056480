const axios = require('axios');
const { ENDPOINT } = require('./constants');
import Cookies from 'js-cookie';

const Logout = (data) => {
  Cookies.remove('accessToken');
  Cookies.remove('userId');
};

module.exports = {
  Logout,
};
