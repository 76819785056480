import { CloseCircleFilled } from '@ant-design/icons';
import {
  Button, Form, Input, message,
} from 'antd';
import { useTranslation } from 'next-i18next';
import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PinInput from 'react-pin-input';
// import { countiesCode } from '../constant/countriesCode';
import _ from 'lodash';
import { SettingActions } from '../reducers/settingReducer';
import { SendEmailVerifyCode, SubmitEmailVerifyCode, ResetPassword } from '../../api/sendEmailVerifyCode';
import StrengthMeter from './StrengthMeter';

function ForgotPasswordModal() {
  const branchId = process.env.BRANCH_ID;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const { t } = useTranslation('common');
  const forgotPasswordShow = useSelector(
    (state) => state.setting.forgotPasswordShow,
  );
  const [step, setStep] = useState(0);
  const [emailOTP, setEmailOTP] = useState('');
  const [isError, setError] = useState(null);
  const [passwordStatus, setPasswordStatus] = useState(null);
  const [invalidCount, setInvalidCount] = useState(0);
  const Ref = useRef(null);
  const [time, setTime] = useState('');

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total, minutes, seconds,
    };
  };
  const startTimer = (e) => {
    const { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTime(
        `${minutes > 9 ? minutes : `0${minutes}`}:${
          seconds > 9 ? seconds : `0${seconds}`}`,
      );
    }
  };
  const clearTimer = (e) => {
    setTime('05:00');
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };
  const getDeadtime = () => {
    const deadline = new Date();
    deadline.setMinutes(deadline.getMinutes() + 5);
    return deadline;
  };

  useEffect(() => {
    if (time === '00:00') {
      setStep(0);
    }
  }, [time]);

  useEffect(() => {
    dispatch(SettingActions.setLoading(false));
  }, []);

  const sendEmailCode = async () => {
    const email = form.getFieldValue('email');
    if (email && /\S+@\S+\.\S+/.test(email)) {
      dispatch(SettingActions.setLoading(true));
      const result = await SendEmailVerifyCode({
        email,
        branchId,
        isForgotPassword: true,
      });
      if (!result?.error) {
        setStep(1);
        clearTimer(getDeadtime());
      } else {
        dispatch(
          SettingActions.setAlert([{ msg: `Fail : ${result?.error?.msg}` }]),
        );
      }
      dispatch(SettingActions.setLoading(false));
    } else {
      dispatch(
        SettingActions.setAlert([{ msg: 'Email is not valid format!' }]),
      );
    }
  };

  const handleEmailOTP = (value, index) => {
    setEmailOTP(value);
  };

  const submitVerifyCode = async () => {
    if (emailOTP.length >= 6) {
      const result = await SubmitEmailVerifyCode({
        email: form.getFieldValue('email'),
        code: emailOTP,
        branchId,
      });
      if (result?.data) {
        setStep(2);
        setEmailOTP('');
      } else {
        dispatch(
          SettingActions.setAlert([{ msg: 'Fail : OTP is not correct!' }]),
        );
        setInvalidCount(invalidCount + 1);
        message.warning(`Invalid count ${invalidCount + 1}`);
        if (invalidCount + 1 >= 3) {
          dispatch(
            SettingActions.setAlert([{ msg: 'Please request new OTP!' }]),
          );
          setInvalidCount(0);
          setStep(0);
        }
      }
    }
  };

  // reset password

  // Function to validate "confirm password" field
  const validateConfirmPassword = (_, value) => {
    const { password } = form.getFieldsValue();
    if (value && value !== password) {
      return Promise.reject('The two passwords that you entered do not match!');
    }

    return Promise.resolve();
  };

  // Function to handle changes in the "password" field
  const handlePasswordChange = () => {
    form.validateFields(['confirmPassword']);

    setError(null);
    let caps; let small; let num; let
      specialSymbol;

    const password = form.getFieldValue('password');
    if (password.length < 4) {
      setError(
        'Password should contain minimum 4 characters, with one UPPERCASE, lowercase, number and special character: @$! % * ? &',
      );
    } else {
      caps = (password.match(/[A-Z]/g) || []).length;
      small = (password.match(/[a-z]/g) || []).length;
      num = (password.match(/[0-9]/g) || []).length;
      specialSymbol = (password.match(/\W/g) || []).length;
      if (caps < 1) {
        setError('Must add one UPPERCASE letter');
      } else if (small < 1) {
        setError('Must add one lowercase letter');
      } else if (num < 1) {
        setError('Must add one number');
      } else if (specialSymbol < 1) {
        setError('Must add one special symbol: @$! % * ? &');
      }
    }
  };

  // const onFinish = async (values) => {
  //   dispatch(SettingActions.setLoading(true));
  // const result = await ForgetPassword({
  //   phoneNumber: values.phone,
  //   dialCode: values.dialCode,
  //   branchId,
  // });

  // if (result.data === 'New Password Sent') {
  //   dispatch(SettingActions.setLoading(false));
  //   dispatch(SettingActions.setAlert([{ msg: 'New Password Sent' }]));
  //   dispatch(SettingActions.setForgotPasswordShow(false));
  //   // router.back();
  // } else {
  //   // show error
  //   dispatch(SettingActions.setLoading(false));
  //   dispatch(
  //     SettingActions.setAlert([{ msg: `Fail : ${result?.error?.msg}` }]),
  //   );
  // }
  // };

  const onFinish = async (values) => {
    if (passwordStatus === 'weak' || isError) {
      const errorMessage = passwordStatus === 'weak' ? 'Please choose a stronger password.' : isError;
      return message.error(errorMessage);
    }

    dispatch(SettingActions.setLoading(true));
    const params = { email: form.getFieldValue('email'), password: values.password, branchId };
    const result = await ResetPassword(params);

    if (!result?.error) {
      setStep(0);
      dispatch(SettingActions.setLoading(false));
      dispatch(SettingActions.setForgotPasswordShow(false));
      dispatch(SettingActions.setAlert([{ msg: 'New Password Update Successfully!' }]));
      form1.resetFields();
    } else {
      // show error
      dispatch(SettingActions.setLoading(false));
      dispatch(
        SettingActions.setAlert([
          { msg: `Fail : ${result?.error?.msg}` },
        ]),
      );
    }
  };

  const _renderStep = () => {
    if (step === 1) {
      return (
        <div>
          <div className='flex flex-col justify-center align-center items-center'>

            <div className='flex flex-col justify-between w-4/5'>
              <p className='text-sm text-center mb-4' style={{ color: '#878787' }}>
                Please enter the OTP(One-Time Password) code sent to
                {' '}
                {form.getFieldValue('email')}
              </p>
              <PinInput
                length={6}
                initialValue=''
                // secret
                // onChange={(value, index) => {}}
                type='numeric'
                inputMode='number'
                style={{ padding: '10px' }}
                inputStyle={{ backgroundColor: '#EDEDED', border: 'none', borderRadius: 12 }}
                onComplete={(value, index) => handleEmailOTP(value, index)}
                autoSelect
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              />
              {/* <div className='text-center mt-4'>
                <p className='text-sm leading-none text-sm mb-1' style={{ color: '#878787' }}>
                  Didn't receive on OTP?
                </p>
                <p className='text-sm cursor-pointer underline'>Resend OTP?</p>
              </div> */}
            </div>
            <div className='text-center'>
              <p className='text-sm'>{time}</p>
            </div>
            <Button
            //   htmlType='submit'
              className='bg-gradient-to-b rounded-lg w-full h-10 mt-4'
              style={{
                padding: '5px',
                backgroundColor: '#23AB5E',
              }}
              onClick={() => submitVerifyCode()}
            >
              <span className='text-xs md:text-sm font-normal uppercase text-white'>
                NEXT
              </span>
            </Button>
          </div>
        </div>
      );
    } if (step === 2) {
      return (
        <Form onFinish={onFinish} form={form} className='mt-5 text-xs'>

          <Form.Item
            name='password'
            label={`${t('register.password')}`}
            rules={[
              {
                required: true,
                message: 'Please input your password !',
              },
            ]}
            className='mb-1'
          >
            <Input
              id='forgot-password-password'
              type='password'
              className='w-full rounded-lg h-10 text-white input-primary mt-2 px-2'
              onChange={handlePasswordChange}
              placeholder='Please enter password'
              maxLength={30}
            />
          </Form.Item>
          <div className='mb-2'>
            {isError !== null && (
              <p className='errors'>
                {' '}
                -
                {' '}
                {isError}
              </p>
            )}
            <StrengthMeter password={form.getFieldValue('password')} actions={setPasswordStatus} />
          </div>
          <Form.Item
            name='confirmPassword'
            dependencies={['password']}
            label={`${t('register.confirmPassword')}*`}
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
              {
                validator: validateConfirmPassword,
              },
            ]}
            className='mb-3'
          >
            <Input
              id='dialog-forgot-password'
              type='password'
              className='w-full rounded-lg h-10 text-white input-primary mt-2 px-2'
              placeholder='Please enter confirm password'
              maxLength={30}
            />
          </Form.Item>

          <Form.Item className='mt-5'>
            <Button
              htmlType='submit'
              className='rounded-lg text-center py-2.5 w-full text-white my-5 font-bold'
              style={{
                backgroundColor: '#23AB5E',
              }}
            >
              <span className='font-normal text-base'>
                SUBMIT
              </span>
            </Button>
          </Form.Item>
        </Form>
      );
    }
    return (
      <Form form={form} onFinish={sendEmailCode}>
        <div className=' m-3 mx-5'>
          {/* <h1>{t('resetPassword.resetPasswordText')}</h1> */}
          {/* <div className='flex inline-block grid grid-cols-3 gap-x-2 mt-3'>
            <div className='col-span-1'>
              <Form.Item
                name='dialCode'
                label={`${t('register.mobileNo')}`}
                rules={[
                  {
                    required: true,
                    message: 'Please input phone number !',
                  },
                ]}
              >
                <select
                  className='flex justify-between w-full rounded-lg h-10 text-white mt-2 px-2 input-primary'
                  style={{ backgroundColor: '#1A242E' }}
                >
                  {countiesCode
                    .filter(
                      (e) => e.name === 'India' || e.name === 'Malaysia',
                    )
                    // sort((a, b) => {
                    //   if (a.name === 'India') {
                    //     return -1;
                    //   }
                    //   return a.name - b.name;
                    // })
                    .map((i, index) => (
                      <option value={i.dial_code} key={index}>
                        {`${i.emoji} ${i.dial_code}`}
                      </option>
                    ))}
                </select>
              </Form.Item>
            </div>
            <div className='col-span-2'>
              <Form.Item
                name='phone'
                label={t('login.mobileNumber')}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  placeholder='Please enter mobile number'
                  className='w-full rounded-lg h-10 text-white my-2 px-2 input-primary'
                />
              </Form.Item>
            </div>
          </div> */}
          <p className='mb-1'>Email Address</p>
          <Form.Item
            name='email'
            // label={t('register.email')}
            rules={[
              {
                required: true,
                message: 'Please input your email !',
              },
            ]}
          >
            <Input
              className='w-full rounded-lg h-10 text-white input-primary px-2'
              // style={{ backgroundColor: '#1A242E' }}
              id='forgot-password-email'
              placeholder='Please enter email'
            />
          </Form.Item>
          <Form.Item>
            <div className='grid grid-cols-2 gap-4 mt-4'>
              <Button
                htmlType='submit'
                className='bg-gradient-to-b rounded-lg w-full mb-5'
                style={{
                  padding: '8px',
                  backgroundColor: '#23AB5E',
                }}
              >
                <span className='text-base font-normal text-white'>
                  {t('resetPassword.resetPassword')}
                </span>
              </Button>
              <Button
                className='bg-red-500 rounded-lg w-full mb-5'
                style={{
                  padding: '8px',
                }}
                onClick={() => dispatch(SettingActions.setForgotPasswordShow(false))}
              >
                <span className='text-base font-normal text-white'>
                  {t('resetPassword.cancel')}
                </span>
              </Button>
            </div>
          </Form.Item>
        </div>
      </Form>
    );
  };
  return (
    <div
      tabIndex='-1'
      className={
        forgotPasswordShow
          ? 'md:p-10 overflow-scroll bg-black bg-opacity-50 fixed top-0 right-0 left-0 z-50 h-modal h-full modal-primary show md:px-60'
          : 'md:p-10 overflow-scroll bg-black bg-opacity-50 fixed top-0 right-0 left-0 z-50 h-modal h-full modal-primary md:px-60'
      }
      role='dialog'
    >
      {/* <div
        className='overlay-closeable'
        onClick={() => dispatch(SettingActions.setForgotPasswordShow(false))}
      /> */}
      <div className='relative py-4 md:pt-5 pb-5 rounded-xl z-10 modal-dialog'>
        <div className='mx-5'>
          <div className='absolute -top-6 right-0 left-0 m-auto text-center md:m-0 md:text-end md:-right-4 md:-top-4'>
            <button
              className='rounded-full close-button relative z-10'
              onClick={() => dispatch(SettingActions.setForgotPasswordShow(false))}
            >
              <CloseCircleFilled
                fill='white'
                style={{ fontSize: '35px', color: 'white' }}
              />
            </button>
          </div>
          <div className='mb-5 text-lg font-medium flex justify-center items-center'>
            {t('login.forgotPasswordText')}
          </div>

          {_renderStep()}

        </div>
      </div>
    </div>
  );
}
export default ForgotPasswordModal;
