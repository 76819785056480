import React, { useLayoutEffect } from 'react';
import Image from 'next/image';
import { useSelector, useDispatch } from 'react-redux';
import {
  loadingGif,
} from '../../images';
import { SettingActions } from '../reducers/settingReducer';

export default function App() {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.setting.loading);

  useLayoutEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        dispatch(SettingActions.setLoading(false));
      }, 1500);
    }
  }, []);

  // const loadingContainer = {
  //   width: '10rem',
  //   height: '10rem',
  //   display: 'flex',
  //   justifyContent: 'space-around',
  // };

  // const loadingCircle = {
  //   display: 'block',
  //   width: '1rem',
  //   height: '1rem',
  //   backgroundColor: '#fcd34d',
  //   borderRadius: '0.5rem',
  // };

  // const loadingBg = {
  //   display: 'block',
  //   width: '2rem',
  //   height: '2rem',
  //   marginLeft: 4,
  //   marginRight: 4,
  //   borderRadius: '0.5rem',
  //   backgroundRepeat: 'no-repeat',
  //   backgroundSize: 'contain',
  // };

  // const loadingCircleTransition = {
  //   duration: 0.35,
  //   ease: 'easeInOut',
  //   repeat: Infinity,
  //   repeatType: 'reverse',
  // };

  return (
    <div>
      <div
        tabIndex='-1'
        className='overflow-y-hidden bg-black bg-opacity-20 overflow-x-hidden fixed top-0 right-0 left-0 z-[100] md:inset-0 h-modal h-full justify-center items-center flex'
        role='dialog'
      >
        <div className='md:hidden'>
          <Image src={loadingGif} width={60} height={60} alt='Cricket betting sites in India' placeholder='empty' />
        </div>
        <div className='md:contents hidden'>
          <Image src={loadingGif} width={80} height={80} alt='Cricket betting sites in India' placeholder='empty' />
        </div>
      </div>
    </div>
  );
}
