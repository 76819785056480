const axios = require('axios');
const Cookies = require('js-cookie');
const { ENDPOINT } = require('./constants');

const sendEmail = (data) => axios
  .request({
    method: 'POST',
    url: `${ENDPOINT}/v1/sendEmail`,
    data,
  })
  .then((response) => response)
  .catch((error) => error);

module.exports = {
  sendEmail,
};
