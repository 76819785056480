const { RECAPTCHA_SITEKEY } = process.env;
const ENDPOINT = 'https://api.betleon77.com';
const ENDPOINT_PAYMENT = 'https://pay.v-pay88.com';
const CANONICAL_URL = 'https://www.bleon77.com';
const AUTHKEY = '6C[D0$+5uK9U|2{izUz';

module.exports = {
  ENDPOINT,
  ENDPOINT_PAYMENT,
  CANONICAL_URL,
  AUTHKEY,
  RECAPTCHA_SITEKEY
};



