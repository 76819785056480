const axios = require('axios');
const Cookies = require('js-cookie');
const { ENDPOINT } = require('./constants');

const getGameCred = (data) => axios.request({
  method: 'GET',
  url: `${ENDPOINT}/gameprofiles`,
  headers: {
    Authorization: `Bearer ${Cookies.get('accessToken') || data?.accessToken}`,
  },
  params: { ...data },
})
  .then((response) => response)
  .catch((error) => ({ error: error?.response?.data?.message }));

module.exports = {
  getGameCred,
};
