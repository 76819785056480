import React, { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useSelector, useDispatch } from 'react-redux';
import { SettingActions } from '../reducers/settingReducer';
import { followUsList, memberServiceList } from '../constant/newFooterContent';
import {
  // logoIcon,
  gaming1Icon,
  security2Icon,
  security3Icon,
  security4Icon,
  security5Icon,
  UPIIcon,
  eighteenPlusDarkLogo
} from '../../images';

function Footer({
  setContactShow,
}) {
  const router = useRouter();
  const dispatch = useDispatch();
  const activeSelected = useSelector((state) => state.setting.selected);
  const isMobile = useSelector((state) => state.setting.isMobile);
  const [isHovered, setIsHovered] = useState('');

  const footerMenuRow2 = [
    {
      key: 12,
      label: 'Responsible Gaming',
      type: '',
      action: () => {
        router.push('/responsible-gaming');
      },
      image: eighteenPlusDarkLogo
    },
    {
      key: 10,
      label: 'Privacy policy',
      type: '',
      // url: '/privacy-policy.pdf',
      action: () => {
        router.push('/privacy-policy');
      },
    },
    {
      key: 11,
      label: 'Contact Us',
      type: '',
      action: () => {
        dispatch(SettingActions.setSelected(11));
        if (router.pathname === '/contact-us') {
          setContactShow(true);
        } else {
          dispatch(SettingActions.setLoading(true));
          router.push('/contact-us');
        }
      },
    },
  ];

  return (
    <div
      className={` ${router.pathname === '/game_website' && 'hidden'}`}
    >
      <div className='bg-white pt-5 md:pt-10 pb-20 md:m-auto md:text-center px-4'>
        {/* <Image alt='BLeon77' src={logoIcon} width={128} height={45} placeholder='empty' /> */}

        <div className='flex flex-col md:flex-row'>

          <div className='flex-col mb-2 md:flex-1'>
            <p className='font-bold text-xs mb-2 flex md:justify-start'>
              Gaming License
            </p>
            <div className='flex items-center mb-2 md:justify-start'>
              <div style={{maxWidth: 100}}>
                <Image 
                  src={gaming1Icon}
                  alt={'GC-Gaming Curacao license'}
                  placeholder='empty'
                />
              </div>
            </div>

            <p className='block text-xs text-justify mb-2'>
              <span className='font-bold text-primary'>BLEON77</span> <span className='opacity-30 font-bold'>is a Registered Trade Mark, brand and registered business 2, Spinola Road, St. Julians STJ3014, Malta. Regulated & Licensed by the Government of Curacao and operates under the Master Licnese of Gaming Services Provider, N.V. #365/ JAZ</span>
            </p>

            <div className='menu-responsive flex flex-row bg-white gap-10 justify-start items-center my-4'>
              {footerMenuRow2.map((i, index) => {
                const isActive = activeSelected === i.key;
                if (i.action) {
                  return (
                    <div className='flex flex-row align-center items-center'>
                      {
                        i.image && 
                          <div style={{maxWidth: 15}}>
                            <Image 
                              src={i.image}
                              alt={`18+ logo ${index}`}
                              placeholder='empty'
                            />
                          </div>
                      }
                      <div
                        className={`opacity-50 text-xs font-normal capitalize cursor-pointer ${i.image && 'ml-2'} ${isActive && 'active'}`}
                        key={`${i.label}-${index}`}
                        onClick={(e) => i.action()}
                      >
                        {i.label}
                      </div>
                    </div>
                  );
                }
                return (
                  <a
                    key={i.key}
                    href={i.url}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <div className='flex flex-row align-center'>
                      {
                        i.image && 
                        <div style={{maxWidth: 20}}>
                          <Image 
                            src={i.image}
                            alt={'18+ logo'}
                            width={isMobile ? 20 : 25}
                            height={isMobile ? 20 : 25}
                            placeholder='empty'
                          />
                        </div>
                      }
                      <div className={`opacity-50 text-xs font-normal capitalize cursor-pointer ${i.image && 'ml-2'} ${isActive && 'active'}`}>
                        {i.label}
                      </div>
                      
                    </div>
                  </a>
                );
              })}
            </div>
          </div>
          
          <div className='md:flex-0.1 md:border md:mx-10 md:border-custom-orange md:opacity-50'/>

          <div className='flex-col md:flex-1'>
              <div>
                  <p className='opacity-30 font-bold text-xs mb-2 flex md:justify-start'>
                    Web Secure
                  </p>
                  <div className='flex flex-row mb-2 items-center md:justify-start'>
                      <div className='mr-6' style={{maxWidth: 100}}>
                        <Image 
                          src={security2Icon}
                          alt={'Treat-Metrix Security'}
                          placeholder='empty'
                        />
                      </div>

                      <div className='mr-6' style={{maxWidth: 100}}>
                        <Image 
                          src={security5Icon}
                          alt={'Shield Security'}
                          placeholder='empty'
                        />
                      </div>
                      
                      <div className='mr-6' style={{maxWidth: 20}}>
                        <Image 
                          src={security3Icon}
                          alt={'IBM Security'}
                          placeholder='empty'
                        />
                      </div>

                      <div className='mr-6' style={{maxWidth: 25}}>
                        <Image 
                          src={security4Icon}
                          alt={'Ping Identity Security'}
                          placeholder='empty'
                        />
                      </div>
                  </div>
                  
                  <p className='opacity-30 font-bold text-xs mb-2 flex md:justify-start'>
                    Payment Method
                  </p>
                  <div className='flex items-center mb-2 md:justify-start'>
                    <div style={{maxWidth: 60}}>
                      <Image 
                        src={UPIIcon}
                        alt={'UPI Payment'}
                        placeholder='empty'
                      />
                    </div>
                  </div>

                  <div className='grid grid-cols-2 gap-6'>
                
                    <div className='md:text-left md:flex md:flex-col md:justify-start '>
                      <p className='opacity-30 font-bold text-xs mb-2'>
                        Follow Us
                      </p>
                      <div className='flex items-center align-center'>
                        {followUsList.map((item, index) => (
                          <Link href={item.link} key={index} passHref>
                            <a
                              className={`group relative inline-block cursor-pointer mr-2`}
                              onMouseEnter={() => setIsHovered(item.title)}
                              onMouseLeave={() => setIsHovered('')}
                            >
                              {isHovered === item.title
                                ? (
                                  // <div style={{minWidth: 20, maxWidth: 40}}>
                                    <Image
                                      src={item.hoverIcon}
                                      alt={item.title}
                                      width={isMobile ? 35 : 40}
                                      height={isMobile ? 35 : 40}
                                      placeholder='empty'
                                    />
                                  // </div>
                                )
                                : (
                                  // <div style={{minWidth: 20, maxWidth: 40}}>
                                    <Image
                                      src={item.icon}
                                      alt={`${item.title}`}
                                      width={isMobile ? 35 : 40}
                                      height={isMobile ? 35 : 40}
                                      placeholder='empty'
                                    />
                                  // </div>
                                )}
                            </a>
                          </Link>
                        ))}
                      </div>
                    </div>

                    <div className='md:text-left md:flex md:flex-col md:justify-start'>
                      <p className='opacity-30 font-bold text-xs mb-2'>
                        Member Service
                      </p>
                      <div className='flex items-center align-center'>
                        {memberServiceList.map((item, index) => (
                          <Link href={item.link} key={index} passHref>
                            <a
                              className={`group relative inline-block cursor-pointer mr-2`}
                              onMouseEnter={() => setIsHovered(item.title)}
                              onMouseLeave={() => setIsHovered('')}
                            >
                              {isHovered === item.title
                                ? (
                                  // <div style={{minWidth: 20, maxWidth: 40}}>
                                    <Image
                                      src={item.hoverIcon}
                                      alt={item.title}
                                      width={isMobile ? 35 : 40}
                                      height={isMobile ? 35 : 40}
                                      placeholder='empty'
                                    />
                                  // </div>
                                )
                                : (
                                  // <div style={{minWidth: 20, maxWidth: 40}}>
                                    <Image
                                      src={item.icon}
                                      alt={`${item.title}`}
                                      width={isMobile ? 35 : 40}
                                      height={isMobile ? 35 : 40}
                                      placeholder='empty'
                                    />
                                  // </div>
                                )}
                            </a>
                          </Link>
                        ))}
                      </div>
                    </div>
              </div>
              </div> 
          </div>

        </div>
      </div>
    </div>
  );
}

export default Footer;
